<template>
  <div class="position-relative">
    <template v-if="creativeType === 'VIDEO_AD'">
      <video v-if="video && video.toLowerCase().endsWith('mp4')" controls height="auto" width="100%">
        <source :src="video" type="video/mp4">
      </video>
    </template>

    <template v-if="creativeType === 'IMAGE_AD'">
      <div>
        <img :src="rectImage" class="img-fluid" />
      </div>
    </template>

    <div class="d-flex">
      <div v-if="logo" class="bg-white p-3" style="max-width: 40%">
        <img :src="logo" class="img-fluid"/>
      </div>
      <div v-if="headline" class="bg-white p-3 flex-grow-1">
        <h4 class="title">{{ headline }}</h4>
        <b-button class="amazon-cta mt-2" variant="outline-secondary">Learn More</b-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AdPreviewAmazon',
  props: {
    creativePlan: {
      type: Object
    },
    smaller: {
      type: Boolean,
      default: false
    },
    zoomed: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    images () {
      return this.creativePlan?.adSpec?.imageAssets
    },
    rectImage () {
      return this.images?.find(image => image.locationInAd === 'rectMarketingImage')?.path
    },
    logo () {
      return this.images?.find(image => image.locationInAd?.includes('logo'))?.path
    },
    videos () {
      return this.creativePlan?.adSpec?.videoAssets
    },
    video () {
      return this.videos[0]?.path
    },
    headline () {
      return this.creativePlan?.adSpec?.textAssets?.find(a => a.locationInAd === 'titles')?.text || ''
    },
    creativeType () {
      return this.creativePlan?.creativeType || ''
    }
  }
}
</script>


<style lang="scss" scoped>
  .title {
    font-family: 'Arial', sans-serif;
    font-weight: bold;
  }
  button.amazon-cta {
    font-family: 'Arial', sans-serif;
    font-weight: bold;
    text-transform: uppercase;
    border-radius: 0;
    border: 2px solid black;
    color: black;
    transition: none;
    &:hover {
      background: black;
    }

  }
</style>
