<template>
  <div :class="['ad-preview-item',
                `ad-preview-item-${view.toLowerCase()}`,
                { 'spaced-out': spacedOut, 'd-inline-block': screenshotMode || reportMode }
               ]"
       :style="screenshotMode && 'max-width: 500px'"
       @click="bubbleClick">
    <h5 v-if="showPreviewLabel" class="mb-4">
      {{previewLabel}}
      <template v-if="view === 'DISPLAY'">
        <b-btn variant="link"
               size="sm"
               ref="mardaPopoverTargetRef"
               class="p-0 align-text-top">
          <span class="round hollow muted sm"><fluency-icon type="info"></fluency-icon></span>
        </b-btn>
        <b-popover :target="mardaPopoverTargetRef"
                   triggers="click"
                   placement="top">
          <template #title>Multi Asset Responsive Display Ads</template>
          <p>Responsive Ads are laid out based on the container the ad is placed in. This results in nearly infinite
            permutations of your ad. The Ad Preview shown here is only one such layout. More example configurations can
            be seen below. </p>
          <img src="~/core-ui/assets/img/google-marda.png" width="250" height="108"/>
        </b-popover>
      </template>
      <a v-p-tooltip.top="generatedLink"
         :href="generatedLink"
         :class="[
            'text-nowrap',
            'overflow-hidden',
            'text-truncate',
            'd-block',
            'align-bottom',
            'small',
            'px-2',
            'heading-link',
            deltaVariantLookup['destinationUrl'] || ''
         ]"
         style="width: 160px;"
         target="_new">
        <fluency-icon type="linkExternal" class="task-list-icon pt-1 pr-1"></fluency-icon>{{generatedLink}}
      </a>
    </h5>

    <div v-if="view === 'SEARCH'" :class="{ 'border': border }">
      <div>
        <strong class="ad-identifier">Ad</strong> &#x2022;
        <span :class="['destination-url', deltaVariantLookup['destinationUrl'] || '']">{{destinationUrlHost}}</span>
        <span v-if="p1" :class="['path1', deltaVariantLookup['path1'] || '']">/{{ stripNoData(p1)}}</span>
        <span v-if="p2" :class="['path2', deltaVariantLookup['path2'] || '']">/{{ stripNoData(p2)}}</span>
        <span v-if="extensionOptions.show && callExtension" class="pl-4 call-extension">{{$filters.phoneNumber(callExtension.phoneNumber,callExtension.countryCode)}}</span>
      </div>
      <a :href="generatedLink" target="_new" :class="['heading-link', {'smaller': smaller}]">
        <template v-if="h1">
          <span v-if="h1" :class="['headline1', deltaVariantLookup['headline1'] || '']">{{ stripNoData(h1) }}</span>
          <span v-if="h2" :class="['headline2', deltaVariantLookup['headline2'] || '']">{{ stripNoData(displayAdGuidelines.display.headlineDelimiter)}} {{ stripNoData(h2)}}</span>
          <span v-if="h3" :class="['headline3', deltaVariantLookup['headline3'] || '']">{{ stripNoData(displayAdGuidelines.display.headlineDelimiter)}} {{ stripNoData(h3)}}</span>
        </template>
      </a>
      <div class="d-flex">
        <div class="description flex-grow-1" :class="{'smaller': smaller}">
          <span :class="['d-inline', deltaVariantLookup['description1']]">{{ stripNoData(d1)}}</span>
          <span :class="['d-inline', deltaVariantLookup['description2']]">{{ stripNoData(d2)}}</span>
        </div>
        <div v-if="activeExtension && activeExtension.imageExtensionPlanId" class="p-2">
          <img :src="activeExtension.image" style="max-width: 80px; border-radius: 8px;"/>
        </div>
      </div>

      <div v-if="extensionOptions.show" class="extensions">
        <ul class="callouts d-flex">
          <li class="callout" v-for="callout in callouts" :key="callout.calloutPlanId">
            {{callout.calloutText}}&nbsp;
          </li>
        </ul>
        <div class="snippets d-flex flex-column align-items-start">
          <span v-for="structuredSnippet in structuredSnippets" :key="structuredSnippet.structuredSnippetPlanId">
            {{structuredSnippet.heading}}: {{structuredSnippet.value ? structuredSnippet.value.replace(/::/gi, ':').replace(/:/gi, ', ') : ''}}
          </span>
        </div>
        <div v-if="activeExtension && (activeExtension.extensionType || '').startsWith('priceExtension')"
             class="priceExtensions ad-extension d-flex justify-content-around">
          <div v-for="(item, index) in priceExtensionRows" :key="item.priceExtensionPriceTableRowId"
               class="d-flex flex-column pr-5 mt-3" style="flex-basis: 32%"
               :class="{'border-right':index < priceExtensionRows.length - 1}">
            <a :href="item.finalUrls">{{item.header}}</a>
            <span>
              <span class="text-muted">{{$filters.convertFromCapsUnderscore(activeExtension.priceQualifier !== 'NONE' ? (activeExtension.priceQualifier || '') : '')}} </span>
              <strong>{{$filters.currency(item.price)}}</strong>
              <span class="text-muted">{{$filters.convertFromCapsUnderscore(item.priceUnit !== 'NONE' ? (item.priceUnit || '') : '')}}</span>
            </span>
            <span class="text-muted">{{item.description}}</span>
          </div>
        </div>
        <div v-else-if="activeExtension && (activeExtension.extensionType || '').startsWith('locationExtension')" class="google-link-color">
          <fluency-icon type="mapMarker"/>
          {{activeExtension.addressLine_1}}, {{activeExtension.addressLine_2}}, {{activeExtension.city}}, {{activeExtension.province}} {{(activeExtension.phoneNumber && activeExtension.phoneNumber !== '') ? '- ' + activeExtension.phoneNumber : ''}}
        </div>
        <div v-else class="sitelinks d-flex flex-wrap justify-content-around">
          <div v-for="(sitelink, index) in sitelinks" :key="`sitelink-${index}-${sitelink.siteLinkPlanId}`"
               class="ad-extension" :class="{'pb-5': index < sitelinks.length - 2}" style="flex: 0 0 34%">
            <a class="sitelinkText" :href="sitelink.siteLinkFinalUrl">
              <span v-if="sitelink.siteLinkText">{{sitelink.siteLinkText}}</span>
              <span v-else><em>Your Sitelink Here</em></span>
            </a>
            <div class="sitelinkDescription1">
              {{sitelink.siteLinkLine_2}}
            </div>
            <div class="sitelinkDescription2">
              {{sitelink.siteLinkLine_3}}
            </div>
          </div>
        </div>
      </div>
      <div class="clearfix"></div>
    </div>

    <div v-if="view === 'CALL'" class="dropshadow bg-white">
      <div class="call-only-ad">

        <a :href="generatedLink" target="_new" :class="['heading-link', {'smaller': smaller}]">
          <img src="~/core-ui/assets/img/phone-icon-blue-20x20.png" width="14" height="14" class="phone-icon" style="margin-right: 5px;" />
          <template v-if="h1 || h2">
            <span class="phoneNumber">Call {{ pn }}</span>
            <span v-if="h1" :class="['headline1', deltaVariantLookup['headlines'] || '', deltaVariantLookup['headline1'] || '']">{{ stripNoData(displayAdGuidelines.display.headlineDelimiter)}} {{ stripNoData(h1) }}</span>
            <span v-if="h2" :class="['headline2', deltaVariantLookup['headline2'] || '']">{{ stripNoData(displayAdGuidelines.display.headlineDelimiter)}} {{ stripNoData(h2)}}</span>
          </template>
        </a>
        <div style="margin-left: 24px">
          <span class="ad">Ad</span>
          <span :class="['destination-url', deltaVariantLookup['destinationUrl'] || '']">{{ destinationUrlHost }}</span>
        </div>
        <hr />
        <template v-if="bn">{{ stripNoData(bn)}}</template>
        <template v-else>BUSINESS NAME</template>
        <template v-if="d1 || d2">-</template>
        {{ stripNoData(d1)}} {{ stripNoData(d2)}}
      </div>
    </div>

    <template v-if="view === 'GMAIL'">
      <!--<span class="gmail-from">{{``}}</span>-->
      <!--<span class="gmail-ad-label">Ad</span><span class="gmail-i"></span>-->
      <!--<span class="gmail-subject">{{``}}</span>-->
      <!--<span class="gmail-body">- {{``}}</span>-->
    </template>

    <template v-if="view === 'GDN_IMAGE_AD' || view === 'RTB_IMAGE_AD' || view === 'RTB_VIDEO_AD' || view === 'DSP_DISPLAY' || view === 'DSP_VIDEO'">
      <div v-if="previewImg" class="position-relative">
        <div v-if="!smaller && !screenshotMode && !reportMode" class="position-absolute" style="top: 0; right: 0;">
          <b-btn class="text-white" squared style="background: rgba(0,0,0,.5)" variant="link" @click="zoomImg()">
            <fluency-icon :type="zoomed ? 'zoomOut' : 'zoomIn'"></fluency-icon>
          </b-btn>
        </div>
        <div class="text-center">
          <a :href="generatedLink"
             target="_new">
            <video v-if="previewImg.toLowerCase().endsWith('mp4')" controls height="auto" width="200">
              <source :src="previewImg" type="video/mp4">
              This Video format is not supported.
            </video>
            <img v-else :class="{ 'zoomed-in': zoomed, 'zoomed-out': !zoomed, smaller}" :src="previewImg" class="img-fluid" />
          </a>
        </div>
      </div>
    </template>

    <template v-if="view === 'DISPLAY'">
      <b-button-group v-if="!smaller && !screenshotMode && !reportMode" size="sm" class="mb-2">
        <b-button :disabled="multiPreviewIndex === 0" class="p-1" variant="link" @click="multiPreviewIndex -= 1">
          <fluency-icon type="chevronLeft"/>
        </b-button>
        <span class="mx-2 preview-switch-text">Preview {{multiPreviewIndex + 1}} / {{displayMultiPreviews.length}}</span>
        <b-button :disabled="multiPreviewIndex === displayMultiPreviews.length - 1" class="p-1" variant="link" @click="multiPreviewIndex += 1">
          <fluency-icon type="chevronRight"/>
        </b-button>
      </b-button-group>

      <div :class="['ad-wrapper', {'smaller ad-wrapper-border': smaller}, activeMultiPreviewType.adWrapperClasses]" :style="`${smaller ? 'height: auto;' : ''}`">

        <div v-if="previewImg" :class="activeMultiPreviewType.previewImgClasses" class="image-container">
          <a :href="generatedLink" target="_new" :class="activeMultiPreviewType.previewAnchorClasses">
            <img :src="previewImg" :width="activeMultiPreviewType.width" class="img-fluid w-100"/>
          </a>
        </div>
        <div :class="activeMultiPreviewType.textContainerClasses" class="text-container flex-grow-1">
          <template v-if="activeMultiPreviewType.type === 'Native'">
            <div class="headline1">{{h1}}</div>
            <div v-if="h2" class="headline2">{{h2}}</div>
            <div v-if="d1" class="description1">{{d1}}</div>
            <div v-if="bn" class="business-name">{{bn}}</div>
            <b-button variant="primary" size="sm">Open</b-button>
          </template>
          <div v-else-if="activeMultiPreviewType.type === 'Skyscraper'" class="h-100 d-flex flex-column justify-content-around align-items-center">
            <div class="headline1">{{h1}}</div>
            <div class="logo-image"><img v-if="logoImg" width="45" height="45" :src="logoImg" class="img-fluid logo-img" /></div>
            <div v-if="h1 || d1" :class="['arrow-link', {'py-3': !smaller, 'py-2': smaller}]">
              <a :href="generatedLink" target="_new">
                <span class="round info lg"><fluency-icon type="chevronRight" class="ml-2 icon-lg"/></span>
              </a>
            </div>
          </div>
          <div v-else-if="activeMultiPreviewType.type === 'Image' || smaller" class="p-2">
            <div class="business-name">{{bn || h1}}</div>
            <div class="d-flex justify-content-between align-items-center">
              <div class="logo-image"><img v-if="logoImg" width="45" height="45" :src="logoImg" class="img-fluid logo-img" /></div>
              <div class="description1">{{d1}}</div>
              <div :class="['arrow-link', {'py-3': !smaller, 'py-2': smaller}]">
                <a :href="generatedLink" target="_new"><span class="round info lg">
                  <fluency-icon type="chevronRight" class="ml-2 icon-lg"/></span>
                </a>
              </div>
            </div>
          </div>
          <template v-else-if="activeMultiPreviewType.type === 'Text'">
            <div class="d-flex">
              <div class="logo-image mr-1"><img v-if="logoImg" :src="logoImg" class="img-fluid logo-img"/></div>
              <div class="headline1">{{h1}}</div>
            </div>
            <div class="description1">{{d1}}</div>
            <div class="business-name">{{bn}}</div>
            <b-button variant="primary" class="align-self-stretch">Open</b-button>
          </template>
        </div>

      </div>
    </template>

    <template v-if="view === 'SHOPPING'">
      <p class="alert alert-info p-2">[Shopping Ad]</p>
      <img src="https://fillmurray.com/200/300"/>
    </template>

    <template v-if="view === 'YOUTUBE' || view === 'DV360'" >
      <ad-preview-youtube :creative-plan="creativePlan" :smaller="smaller"></ad-preview-youtube>
    </template>

    <template v-if="view === 'FACEBOOK'">
      <ad-preview-facebook :creative-plan="creativePlan"
                           :preview-type="smaller ? 'SMALL' : detailed ? 'DETAILED' : 'SAMPLE'"
                           :highlight-map="deltaVariantLookup"
                           :demo-overrides="demoOverrides"
                           :report-mode="reportMode"
                           :preview-asset-index="previewAssetIndex"/>
    </template>

    <template v-if="view === 'WAZE'">
      <ad-preview-waze :creative-plan="creativePlan"
                       :preview-type="smaller ? 'SMALL' : 'SAMPLE'"></ad-preview-waze>
    </template>

    <template v-if="view === 'HTML5_DISPLAY'">
      <h-t-m-l5-display-ads :value="{ adSpec: creativePlan.adSpec}"
                            :report-mode="reportMode"
                            :show-label="false"
                            :img-max-height="imgMaxHeight"
                            :show-preview-message="showPreviewLabel"></h-t-m-l5-display-ads>
    </template>

    <template v-if="view === 'LOCAL'">
      <ad-preview-local :creative-plan="creativePlan" :show-image="!smaller"></ad-preview-local>
    </template>

    <template v-if="view === 'AUDIO'">
      <audio v-if="audioSourceUrl" preload="metadata" controls :src="audioSourceUrl" />
    </template>

    <template v-if="view === 'DISCOVERY_MULTI_ASSET_AD'">
      <ad-preview-discovery :creative-plan="creativePlan" :show-image="!smaller"></ad-preview-discovery>
    </template>

    <template v-if="view === 'DISCOVERY_VIDEO_RESPONSIVE_AD'">
      <ad-preview-discovery-video :creative-plan="creativePlan" :smaller="smaller"></ad-preview-discovery-video>
    </template>

    <template v-if="view === 'PERFORMANCE_MAX'">
      <ad-preview-performance-max :creative-plan="creativePlan" :show-image="!smaller" :extension-options="extensionOptions"/>
    </template>

    <template v-if="view === 'PINTEREST'">
      <ad-preview-pinterest :creative-plan="creativePlan" :smaller="smaller"></ad-preview-pinterest>
    </template>

    <template v-if="view === 'AMAZON'" >
      <ad-preview-amazon :creative-plan="creativePlan" :smaller="smaller"/>
    </template>

    <template v-if="view === 'HTML_DSP_AD'">
      <ad-preview-dsp-html :creative-plan="creativePlan" :smaller="smaller"></ad-preview-dsp-html>
    </template>

    <template v-if="view === 'UNKNOWN'">
      <p class="alert alert-warning p-2">Unable to generate Ad Preview.</p>
    </template>
  </div>
</template>

<script setup>
import { ref } from 'vue'
const mardaPopoverTargetRef = ref(null)
</script>

<script>
import DisplayAds from 'core-ui/assets/js/constants/displayAds'
import _ from 'lodash'
import AdPreviewFacebook from './adPreviewFacebook'
import AdPreviewWaze from './adPreviewWaze'
import AdPreviewYoutube from './adPreviewYoutube'
import HTML5DisplayAds from './HTML5DisplayAds'
import AdPreviewLocal from './adPreviewLocal'
import AdPreviewDiscovery from './adPreviewDiscovery'
import AdPreviewDiscoveryVideo from './adPreviewDiscoveryVideo'
import AdPreviewPerformanceMax from './adPreviewPerformanceMax'
import AdPreviewPinterest from './adPreviewPinterest'
import AdPreviewAmazon from './adPreviewAmazon'
import adPreviewDspHtml from './adPreviewDspHtml.vue'

export default {
  name: 'adPreview',
  components: {
    AdPreviewPinterest,
    AdPreviewPerformanceMax,
    AdPreviewDiscovery,
    AdPreviewLocal,
    HTML5DisplayAds,
    AdPreviewYoutube,
    AdPreviewFacebook,
    AdPreviewWaze,
    AdPreviewAmazon,
    AdPreviewDiscoveryVideo,
    adPreviewDspHtml
  },
  props: {
    creativePlan: {
      type: Object,
      default: () => {
        return null
      }
    },
    account: {
      type: Object,
      default: () => {
        return null
      }
    },
    extensionOptions: {
      type: Object,
      default: () => ({
        show: false,
        activeExtensionId: null,
        activeExtensionType: null
      })
    },
    spacedOut: {
      type: Boolean,
      default: false
    },
    screenshotMode: {
      type: Boolean,
      default: false
    },
    reportMode: {
      type: Boolean,
      default: false
    },
    channelId: {
      type: Number
    },
    showPreviewLabel: Boolean,
    previewLabel: {
      type: String,
      default: 'Ad Preview'
    },
    smaller: {
      type: Boolean,
      default: false
    },
    detailed: { // used by FB ad preview to render ad content in a compact format
      type: Boolean,
      default: false
    },
    delta: Object, // another ad preview object. component will highlight diffs
    deltaVariant: {
      type: String,
      default: 'warning'
    },
    allowClickToBubble: {
      type: Boolean,
      default: false
    },
    border: {
      type: Boolean,
      default: false
    },
    previewAssetIndex: { // when there are multiple assets to preview (multiple images for instance), use this prop to force preview a specific asset, such previewing the 'active' item that's currently being edited
      type: Number,
      default: 0
    },
    demoOverrides: { // overrides for Demo purposes. In actual use should always be `null`
      type: Object,
      default: null
    },
    imgMaxHeight: { // only for html5 display ad
      type: Number
    }
  },
  data () {
    return {
      displayAdGuidelines: DisplayAds,
      adPartKeys: [
        // 'headlines',
        'headline1',
        'headline2',
        'headline3',
        'destinationUrl',
        // 'descriptions',
        'description1',
        'description2',
        'path1',
        'path2',
        'body',
        'title',
        'description',
        'callToAction',
        'website_url',
        'display_url'
      ],
      zoomed: false,
      displayMultiPreviews: [
        { type: 'Image', adWrapperClasses: 'd-flex flex-column image' },
        { type: 'Skyscraper', adWrapperClasses: 'd-flex flex-column text-center skyscraper' },
        { type: 'Native', adWrapperClasses: 'd-flex flex-2x-cols native', previewImgClasses: 'w-50 overflow-hidden', textContainerClasses: 'w-50 d-flex flex-column justify-content-around align-items-start' },
        { type: 'Text', adWrapperClasses: 'd-flex flex-column text', previewImgClasses: 'd-none', textContainerClasses: 'd-flex flex-column justify-content-between px-4 pt-4 pb-2' }],
      multiPreviewIndex: 0
    }
  },
  computed: {
    displayAdFallbackImage () {
      let returnVal = null

      if (this.view === 'HTML5_DISPLAY') {
        if (this.creativeSpec?.imageAssets) {
          this.creativeSpec.imageAssets.forEach(i => {
            if (i.locationInAd === 'fallback') {
              returnVal = i
            }
          })
        }
      }

      return returnVal
    },
    activeMultiPreviewType () {
      return this.displayMultiPreviews[this.multiPreviewIndex || 0]
    },
    creativeSpec () {
      return this.creativePlan?.previewAdSpec || this.creativePlan?.adSpec || {}
    },
    name () {
      return this.creativePlan ? this.creativePlan.name : ''
    },
    creativeType () {
      return this.creativePlan ? this.creativePlan.creativeType : 'UNKNOWN'
    },
    showCallButton () {
      return this.creativeType === 'CALL_ONLY_AD'
    },
    view () {
      switch (this.creativeType) {
        case 'TEXT':
        case 'TEXT_AD':
        case 'EXPANDED_TEXT_AD':
        case 'RESPONSIVE_SEARCH_AD':
        case 'EXPANDED_DYNAMIC_SEARCH_AD':
        case 'DYNAMIC_SEARCH_AD':
          return 'SEARCH'

        case 'CALL_ONLY_AD':
          return 'CALL'

        case 'GMAIL_AD':
          return 'GMAIL'

        case 'IMAGE_AD':
          if (this.channelId === 2) {
            return 'FACEBOOK'
          } else if (this.channelId === 3) {
            return 'GDN_IMAGE_AD'
          } else if (this.channelId === 5) {
            return 'AMAZON'
          } else if (this.channelId === 11 || this.channelId === 14 || this.channelId === 15 || this.channelId === 16) {
            return 'RTB_IMAGE_AD'
          } else if (this.channelId === 29) {
            return 'DSP_DISPLAY'
          }
          return 'UNKNOWN'

        case 'VIDEO_AD':
          if (this.channelId === 2) {
            return 'FACEBOOK'
          } else if (this.channelId === 5) {
            return 'AMAZON'
          } else if (this.channelId === 10 || this.channelId === 27) {
            return 'YOUTUBE'
          } else if (this.channelId === 18) {
            return 'RTB_VIDEO_AD'
          } else if (this.channelId === 30) {
            return 'DSP_VIDEO'
          } else if (this.channelId === 31) {
            return 'DV360'
          }
          return 'UNKNOWN'

        case 'CAROUSEL_AD':
        case 'MEDIA_DATA_AD':
        case 'DYNAMIC_PRODUCT_AD':
        case 'COLLECTION_AD':
        case 'PROMOTED_POST':
        case 'SLIDESHOW_AD':
          return 'FACEBOOK'

        case 'RESPONSIVE_DISPLAY_AD':
        case 'TEMPLATE_AD':
        case 'MULTI_ASSET_RESPONSIVE_DISPLAY_AD':
          return 'DISPLAY'

        case 'PRODUCT_AD':
          return 'SHOPPING'

        case 'WAZE_SEARCH_AD':
        case 'TAKEOVER_AD':
        case 'PIN_AD':
          return 'WAZE'

        case 'HTML_DISPLAY_AD':
          return 'HTML5_DISPLAY'

        case 'LOCAL_AD':
          return 'LOCAL'

        case 'AUDIO_AD':
          return 'AUDIO'

        case 'DISCOVERY_MULTI_ASSET_AD':
          return 'DISCOVERY_MULTI_ASSET_AD'

        case 'DISCOVERY_VIDEO_RESPONSIVE_AD':
          return 'DISCOVERY_VIDEO_RESPONSIVE_AD'

        case 'PERFORMANCE_MAX':
          return 'PERFORMANCE_MAX'

        case 'PINTEREST_REGULAR_AD':
        case 'PINTEREST_CAROUSEL_AD':
        case 'PINTEREST_STORY_AD':
        case 'PINTEREST_VIDEO_AD':
        case 'PINTEREST_MAX_VIDEO_AD':
        case 'PINTEREST_SHOPPING_AD':
        case 'PINTEREST_SHOP_THE_PIN_AD':
          return 'PINTEREST'
        case 'HTML_DSP_AD':
        case 'STATIC_HTML_DSP_AD':
          return 'HTML_DSP_AD'
        default:
          return 'UNKNOWN'
      }
    },
    accountPlan () {
      if (this.account) {
        return this.account
      }
      if (this.creativePlan?.accountPlan) {
        return this.creativePlan.accountPlan
      }
      if (this.$store.getters.activeItem && this.$store.getters.accountsById && this.$store.getters.activeItem?.accountPlanId) {
        const account = this.$store.getters.accountsById.get(this.$store.getters.activeItem.accountPlanId)
        if (account) {
          return account
        }
      }
      if (this.$store.getters.account && this.$store.getters.account?.hasOwnProperty('accountPlanId')) {
        return this.$store.getters.account
      }
      if (this.$store.state.createFlow && this.$store.state.createFlow?.accountPlan?.accountPlanId === this.creativePlan?.accountPlanId) {
        return this.$store.state.createFlow.accountPlan
      }
      return null
    },
    destinationUrl () {
      if (this.creativePlan?.destinationUrl) {
        return this.creativePlan.destinationUrl
      }

      if (this.accountPlan?.primaryDomain) {
        return this.accountPlan.primaryDomain
      }
      return 'http://www.example.com'
    },
    destinationUrlHost () {
      let url = this.destinationUrl
      if (!url) {
        url = this.accountPlan?.primaryDomain
      }

      if (url) {
        const str = url.replace('&lt;no-data&gt;', '').replace('http://', '').replace('https://', '')
        if (str.indexOf('/') > 0) {
          url = str.split('/')[0]
        } else if (str.indexOf('?') > 0) {
          url = str.split('?')[0]
        } else {
          url = str
        }

        return url
      } else {
        return ''
      }
    },
    generatedLink () {
      return `${this.destinationUrl}`
    },
    deltaVariantLookup () {
      if (!this.delta || !this.deltaVariant || this.deltaVariant === 'none' || !this.delta.hasOwnProperty('adSpec')) {
        return {}
      } else {
        const deltaCreativeSpec = this.delta.previewAdSpec || this.delta.adSpec || {}
        const origTextAssets = this.creativeSpec.textAssets?.toMap(t => t.locationInAd, t => t.text?.toLowerCase() || '') || {}
        const deltaTextAssets = deltaCreativeSpec.textAssets?.toMap(t => t.locationInAd, t => t.text?.toLowerCase() || '') || {}

        const lookup = this.adPartKeys
          .filter(key => {
            // TODO: This isn't right for Responsive Search Ads, close but not right.
            return deltaTextAssets.hasOwnProperty(key) && origTextAssets.hasOwnProperty(key) && deltaTextAssets[key] !== origTextAssets[key]
          })
          .reduce((lookup, key) => {
            lookup[key] = `outline-${this.deltaVariant}`
            return lookup
          }, {})

        const origSubAssets = (this.creativeSpec.imageAssets || []).concat(this.creativeSpec.videoAssets || [])
        origSubAssets.sort((a, b) => a.order - b.order)
        const deltaSubAssets = (deltaCreativeSpec.imageAssets || []).concat(deltaCreativeSpec.videoAssets || [])
        deltaSubAssets.sort((a, b) => a.order - b.order)

        _.zip(origSubAssets, deltaSubAssets)
          .forEach((assetPair, index) => {
            const origSubTextAssets = assetPair[0]?.textAssets?.toMap(t => t.locationInAd, t => t.text?.toLowerCase() || '') || {}
            const deltaSubTextAssets = assetPair[1]?.textAssets?.toMap(t => t.locationInAd, t => t.text?.toLowerCase() || '') || {}
            this.adPartKeys
              .filter(key => !!deltaSubTextAssets[key] && !!origSubTextAssets[key] && deltaSubTextAssets[key] !== origSubTextAssets[key])
              .forEach(key => { lookup[`sub${index}-${key}`] = `outline-${this.deltaVariant}` })
          })

        if (this.generatedLink !== this.delta.destinationUrl) {
          lookup.destinationUrl = `outline-${this.deltaVariant}`
        }

        if (this.creativeType === 'RESPONSIVE_SEARCH_AD') {
          for (const [index, text] of this.randomizedHeadlines.entries()) {
            if (!this.delta.adSpec.textAssets.some(asset => asset.locationInAd === 'headlines' && asset.text.toLowerCase() === text.toLowerCase())) {
              const n = index + 1
              lookup[`headline${n}`] = `outline-${this.deltaVariant}`
            }
          }
          for (const [index, text] of this.randomizedDescriptions.entries()) {
            if (!this.delta.adSpec.textAssets.some(asset => asset.locationInAd === 'descriptions' && asset.text.toLowerCase() === text.toLowerCase())) {
              const n = index + 1
              lookup[`description${n}`] = `outline-${this.deltaVariant}`
            }
          }
        }

        this.$emit('delta', Object.keys(lookup)) // emits array of differences: [HEADLINE_1, DESCRIPTION]

        return lookup
      }
    },
    randomizedHeadlines () {
      return this.responsiveSearchAdPrep('headlines')
    },
    randomizedDescriptions () {
      return this.responsiveSearchAdPrep('descriptions')
    },
    h1 () {
      if (this.creativeType === 'MULTI_ASSET_RESPONSIVE_DISPLAY_AD') {
        return this.pullHeadline(Math.round(Math.random() * 100) + this.multiPreviewIndex)
      }
      return this.pullHeadline(1) // title
    },
    h2 () {
      if (this.creativeType === 'MULTI_ASSET_RESPONSIVE_DISPLAY_AD') {
        return this.pullHeadline(Math.round(Math.random() * 100) + this.multiPreviewIndex)
      }
      return this.pullHeadline(2) // description
    },
    h3 () {
      if (this.creativeType === 'MULTI_ASSET_RESPONSIVE_DISPLAY_AD') {
        return this.pullHeadline(Math.round(Math.random() * 100) + this.multiPreviewIndex)
      }
      return this.pullHeadline(3)
    },
    d1 () {
      if (this.creativeType === 'MULTI_ASSET_RESPONSIVE_DISPLAY_AD') {
        return this.pullDescription(Math.round(Math.random() * 100) + this.multiPreviewIndex, this.randomizedDescriptions)
      }
      return this.pullDescription(1, this.randomizedDescriptions) // body
    },
    d2 () {
      if (this.creativeType === 'MULTI_ASSET_RESPONSIVE_DISPLAY_AD') {
        return this.pullDescription(Math.round(Math.random() * 100) + this.multiPreviewIndex, this.randomizedDescriptions)
      }
      return this.pullDescription(2, this.randomizedDescriptions)
    },
    p1 () {
      return this.pullPath(1) // url
    },
    p2 () {
      return this.pullPath(2) // cta
    },
    bn () {
      let path = ''

      if (this.creativeSpec.hasOwnProperty('textAssets')) {
        path = this.creativeSpec.textAssets.find(n => n.locationInAd === 'businessName')
        return (path) ? path.text : (this.businessName) ? this.businessName : ''
      }
      return ''
    },
    pn () {
      let path = ''

      if (this.creativeSpec.hasOwnProperty('textAssets')) {
        path = this.creativeSpec.textAssets.find(n => n.locationInAd === 'phoneNumber')
        return (path) ? path.text : (this.phoneNumber) ? this.phoneNumber : ''
      }
      return ''
    },
    extensions () {
      return (this.creativePlan.extensions || []).filter(ext => ext.enabled)
    },
    activeExtension () {
      if (this.extensionOptions.activeExtension) {
        const extensionIndex = this.extensions?.indexOf(this.extensionOptions.activeExtension)
        if (extensionIndex >= 0) {
          return this.extensions[extensionIndex]
        }
      }
      if (this.extensionOptions.activeExtensionId) {
        const index = this.extensions.findIndex(ext => ext.extensionPlanId === this.extensionOptions.activeExtensionId &&
          (!this.extensionOptions.activeExtensionType || ext.extensionType === this.extensionOptions.activeExtensionType))
        if (index > -1) {
          return this.extensions[index]
        }
      }
      return this.extensions.length > 0 ? this.extensions[0] : null
    },
    callExtension () {
      const calls = this.extensions.filter(ext => ext.extensionType?.startsWith('callExtension'))
      if (calls.length > 0) {
        return calls[0]
      }
      return null
    },
    callouts () {
      const callouts = this.extensions
        .filter(ext => ext.extensionType?.startsWith('callout'))
        .slice(0, 10)
        .distinct(ext => ext?.calloutText)
        .slice(0, 4)
      return callouts
    },
    structuredSnippets () {
      const snippets = this.extensions
        .filter(ext => ext.extensionType?.startsWith('structuredSnippet'))
        .slice(0, 2)
      return snippets
    },
    priceExtensionRows () {
      if (this.activeExtension && this.activeExtension?.extensionType?.startsWith('priceExtension')) {
        const rows = (this.activeExtension?.priceExtensionPriceTableRows || [])
        return rows.slice(0, 3)
      }
      return []
    },
    sitelinks () {
      const sitelinks = this.extensions.filter(ext => ext.extensionType?.startsWith('siteLink'))
      if (sitelinks.length < 2) {
        return []
      }

      // this gets an even number of sitelinks
      return sitelinks
        .slice(0, 6)
        .slice(0, (sitelinks.length % 2 === 1 ? sitelinks.length - 1 : sitelinks.length))
    },
    headineSizes () {
      if (this.view !== 'DISPLAY') {
        return null
      }

      const breakpoints = [20, 15, 10]
      let fontSizes = [32, 36, 40, 42]
      let lineHeights = [45, 50, 56, 59]

      // reduce everything by half
      if (this.smaller) {
        fontSizes = fontSizes.map(function (n) {
          return n / 2
        })
        lineHeights = lineHeights.map(function (n) {
          return n / 2
        })
      }

      return {
        fontSize: (this.h1.length > breakpoints[0])
          ? fontSizes[0]
          : (this.h1.length > breakpoints[1])
              ? fontSizes[1]
              : (this.h1.length > breakpoints[2])
                  ? fontSizes[2]
                  : fontSizes[3],
        lineHeight: (this.h1.length > breakpoints[0])
          ? lineHeights[0]
          : (this.h1.length > breakpoints[1])
              ? lineHeights[1]
              : (this.h1.length > breakpoints[1])
                  ? lineHeights[2]
                  : lineHeights[3]
      }
    },
    descriptionSizes () {
      if (this.view !== 'DISPLAY') {
        return null
      }

      const breakpoints = [60, 45, 20]
      let fontSizes = [20, 24, 26, 28]
      let lineHeights = [30, 36, 39, 42]

      // reduce everything by half
      if (this.smaller) {
        fontSizes = fontSizes.map(function (n) {
          return n / 2
        })
        lineHeights = lineHeights.map(function (n) {
          return n / 2
        })
      }

      return {
        fontSize: (this.d1.length > breakpoints[0])
          ? fontSizes[0]
          : (this.d1.length > breakpoints[1])
              ? fontSizes[1]
              : (this.d1.length > breakpoints[2])
                  ? fontSizes[2]
                  : fontSizes[3],
        lineHeight: (this.d1.length > breakpoints[0])
          ? lineHeights[0]
          : (this.d1.length > breakpoints[1])
              ? lineHeights[1]
              : (this.d1.length > breakpoints[1])
                  ? lineHeights[2]
                  : lineHeights[3]
      }
    },
    previewImg () {
      let path = ''

      if (this.creativeSpec.imageAssets) {
        if (this.view === 'DISPLAY' || this.view === 'GDN_IMAGE_AD') {
          // get non-logo, non-square image
          let paths = []
          paths = this.creativeSpec.imageAssets.filter(n => n.locationInAd !== 'logoImages' && n.locationInAd.indexOf('square') === -1 && n.path).map(n => n.path)

          // if no image set yet settle for a square one
          if (paths.length === 0) {
            paths = this.creativeSpec.imageAssets.filter(n => n.locationInAd !== 'logoImages' && n.path).map(n => n.path)
          }
          if (paths.length > 0) {
            const inboundsIndex = (Math.round(Math.random() * 100) + this.multiPreviewIndex) % paths.length
            path = paths[inboundsIndex]
          }
        } else if (this.view === 'FACEBOOK') {
          if (this.creativeType === 'MEDIA_DATA_AD') {
            const images = this.creativeSpec.imageAssets.filter(t => t.locationInAd.includes('images'))
            if (images.length > 0) {
              path = images[_.random(images.length - 1)].path
            }
          }
        } else if (this.view === 'RTB_IMAGE_AD' || this.view === 'DSP_DISPLAY') {
          this.creativeSpec.imageAssets.forEach(n => {
            if (n.locationInAd === 'image' || n.locationInAd === 'imageUrl') {
              path = n.path
            }
          })
        } else if (this.view === 'RTB_VIDEO_AD' || this.view === 'DSP_VIDEO') {
          this.creativeSpec.videoAssets.forEach(n => {
            if (n.locationInAd === 'image' || n.locationInAd === 'videoUrl') {
              path = n.path
            }
          })
        }
      }
      return path
    },
    logoImg () {
      let path = ''

      if (this.view === 'DISPLAY' && this.creativeSpec.hasOwnProperty('imageAssets')) {
        this.creativeSpec.imageAssets.forEach(n => {
          if (n.locationInAd === 'logoImages') {
            path = n.path
          }
        })
      }

      return path
    },
    audioSourceUrl () {
      let path = ''
      const audioUrlTextAssets = this.creativeSpec.textAssets?.filter(asset => asset.locationInAd === 'audioUrl')
      if (audioUrlTextAssets.length > 0) {
        path = audioUrlTextAssets[0].text
      }
      return path
    }
  },
  methods: {
    zoomImg () {
      this.$setCompat(this, 'zoomed', !this.zoomed)
    },
    bubbleClick (evt) {
      // only bubble a click if the user clicked on something besides a link and we have specifically asked for the click to bubble
      const userClickedOnALink = evt?.path?.filter(elm => elm.localName === 'a').length > 0 || false
      if (!this.allowClickToBubble || userClickedOnALink) {
        evt.stopPropagation()
      }
    },
    pullHeadline: function (index) {
      if (this.view === 'SEARCH' || this.view === 'CALL' || this.view === 'GMAIL' || this.creativeType === 'MULTI_ASSET_RESPONSIVE_DISPLAY_AD') {
        let headline

        if (this.creativeSpec.hasOwnProperty('textAssets')) {
          this.creativeSpec.textAssets.forEach(n => {
            if (n.locationInAd === `headline${index}`) {
              headline = n.text
            }
          })
        }
        const indexInBounds = (index - 1) % this.randomizedHeadlines.length
        return this.randomizedHeadlines[indexInBounds] || headline || this[`headline${index}`] || ''
      } else if (this.view === 'DISPLAY') {
        let hl = ''

        if (this.creativeSpec.hasOwnProperty('textAssets')) {
          this.creativeSpec.textAssets.forEach(n => {
            if (index === 1 && n.locationInAd === 'shortHeadline') {
              hl = n.text
            } else if (index === 2 && n.locationInAd === 'longHeadline') {
              hl = n.text
            }
          })
        }

        return hl
      }

      return ''
    },
    pullDescription: function (index, pool = []) {
      if (this.view === 'SEARCH' || this.view === 'CALL' || this.view === 'GMAIL' || this.creativeType === 'MULTI_ASSET_RESPONSIVE_DISPLAY_AD') {
        let description

        if (this.creativeSpec.hasOwnProperty('textAssets')) {
          this.creativeSpec.textAssets.forEach(n => {
            if (n.locationInAd === `description${index}`) {
              description = n.text
            }
          })
        }
        const indexInBounds = (index - 1) % pool.length
        return pool[indexInBounds] || description || this[`description${(index === 1) ? '' : index}`] || ''
      } else if (this.view === 'DISPLAY') {
        let desc = ''

        if (index === 1 && this.creativeSpec.hasOwnProperty('textAssets')) {
          this.creativeSpec.textAssets.forEach(n => {
            if (n.locationInAd === `description${index}`) {
              desc = n.text
            }
          })

          return desc
        }
      }

      return ''
    },
    pullPath: function (index) {
      let path = ''

      if (this.creativeSpec.hasOwnProperty('textAssets')) {
        path = this.creativeSpec.textAssets.find(n => n.locationInAd === `path${index}`)
        return (path) ? path.text : (this[`path${index}`]) ? this[`path${index}`] : ''
      }
      return ''
    },
    responsiveSearchAdPrep: function (type) {
      if (this.creativeType === 'MULTI_ASSET_RESPONSIVE_DISPLAY_AD') {
        if (this.creativeSpec.hasOwnProperty('textAssets')) {
          const assets = this.creativeSpec.textAssets.filter(n => n.locationInAd === type)
          const pinned = []
          for (let i = 0; i < assets.length; i++) {
            if (assets[i].pinned) {
              pinned.push(...assets.splice(i, 1))
            }
          }
          return pinned.concat(_.shuffle(assets))
            .map(k => k.text || '<empty>')
        }
      } else if (this.creativeType === 'RESPONSIVE_SEARCH_AD') {
        let assets = this.creativeSpec.textAssets.filter(n => n.locationInAd === type)

        // if broadcast is applied, show broadcast in position 1 and randomize the rest
        const broadcastMessage = this.creativeSpec.textAssets.find(asset => asset.locationInAd?.toLowerCase() === `broadcastrsa${type.substring(0, type.length - 1)}`)
        if (broadcastMessage) {
          if (!broadcastMessage.pinnedLocation) {
            broadcastMessage.pinnedLocation = 'Show only in position 1'
          }
          assets = assets.filter(a => a.text !== broadcastMessage.text).map(a => ({ ...a, pinnedLocation: '' }))
          assets.push(broadcastMessage)
        }

        // apply pinning rules and randomize
        const p1 = _.shuffle(assets.filter(a => a.pinnedLocation === 'Show only in position 1'))[0]
        const p2 = _.shuffle(assets.filter(a => a.pinnedLocation === 'Show only in position 2'))[0]
        const p3 = _.shuffle(assets.filter(a => a.pinnedLocation === 'Show only in position 3'))[0]
        const noP = _.shuffle(assets.filter(a => !a.pinnedLocation))
        if (p1) {
          noP.splice(0, 0, p1)
        }
        if (p2) {
          noP.splice(1, 0, p2)
        }
        if (p3) {
          noP.splice(2, 0, p3)
        }
        return noP.map(a => a.text)
      }
      return []
    },
    stripNoData (val) {
      if (val && typeof val === 'string' && val !== '') {
        return val.replace('&lt;no-data&gt;', '').replace('http://', '').replace('https://', '')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .ml-2 {
    margin-left: 0.25rem !important;
  }
  .zoomed-out {
    max-width: 300px;
    max-height: 300px;

    &.smaller {
      max-width: 200px;
    }
  }

  .ad-preview-item {
    display: block;
    width: auto;
    max-width: 616px;

    &.spaced-out {
      margin: 1rem 0;
    }
  }

  .google-link-color {
    color: rgb(26, 13, 171);
  }

  .ad-preview-item-search,
  .ad-preview-item-call {
    min-width: 350px;
    .headline1,
    .headline2,
    .headline3,
    .phoneNumber {
      color: rgb(26, 13, 171);
      font-size: 1rem;
    }

    .path1 {
      margin-left: -3px;
      padding: 0;
    }

    .path2 {
      margin-left: -4px;
      padding: 0;
    }

    .description,
    .description1,
    .description2 {
      font-size: 14px;
    }

    .headline-link:hover {
      text-decoration: underline;
    }

    .smaller {
      .headline1,
      .headline2,
      .headline3 {
        font-size: 110%;
      }

      .ad {
        font-size: 80%;
      }
    }

    .ad {
      background-color: #fff;
      border-radius: 3px;
      color: #006621;
      font-size: 11px;
      border: 1px solid #006621;
      padding: 1px 3px 0 2px;
      line-height: 11px;
      vertical-align: baseline;
    }

    .ad-identifier,
    .destination-url,
    .path1,
    .path2,
    .call-extension {
      font-size: 14px;
      //color: #006621;
    }

    .ad-extension a {
      font-size: 110%;
      color: #1a0dab;
      font-family: Arial;
    }

    .faux-call-button {
      background: #f6f6f6;
    }

    .phone-icon {
      margin-top: -3px;
    }
  }

  .ad-preview-item-call {
    max-width: 414px;

    /*.call-only-ad {
      border-radius: 8px;
      box-shadow: 0 2px 2px 0 rgba(0,0,0,.14), 0 3px 1px -2px rgba(0,0,0,.12), 0 1px 5px 0 rgba(0,0,0,.2);
      padding: 16px;
    }*/
  }

  .border {
    border-radius: 8px;
    padding: 8px 8px;
  }
  .dropshadow {
    border-radius: 8px;
    box-shadow: 0 2px 2px 0 rgba(0,0,0,.14), 0 3px 1px -2px rgba(0,0,0,.12), 0 1px 5px 0 rgba(0,0,0,.2);
    padding: 16px;
  }

  .ad-preview-item-gmail {
    font-family: Roboto, RobotoDraft, Helvetica, Arial, sans-serif;
    font-size: 14px;
    /*background-color: #EEF0F4;*/

    .gmail-from {
      font-weight: bold;
      color: #333333;
    }

    .gmail-ad-label {
      color: rgb(0, 102, 33);
      border: 1px solid rgb(0, 102, 33);
      background-color: transparent;
      -webkit-border-radius: 3px;
      border-radius: 3px;
      font-size: 12px;
      display: inline-block;
      line-height: 15px;
      padding: 0 3px;
      font-weight: normal;
      vertical-align: baseline;
    }

    .gmail-i {
      display: inline-block;
      background-image: url(https://ssl.gstatic.com/ui/v1/icons/mail/ads_info_grey.png);
      background-repeat: no-repeat;
      cursor: pointer;
      height: 13px;
      margin-left: 4px;
      position: relative;
      top: 3px;
      width: 13px;
    }

    .gmail-subject {
      font-weight: bold;
      color: #1f1f1f;
    }

    .gmail-body {
      color: #5F6368
    }
  }

  .ad-preview-item-display {

    * {
      transition: all 100ms ease;
    }

    .preview-switch-text {
      font-size: 12px;
    }
    .ad-wrapper {
      margin: 0 auto;
      //max-width: 320px;
      //background: rgb(51, 57, 68);
      background: white;
      &.ad-wrapper-border {
        border: 1px solid #e6e7e8;
      }
      font-family: Roboto, RobotoDraft, Helvetica, Arial, sans-serif;
      //color: white;
      color: rgb(51, 57, 68);
      a {
        //color: white !important;
        color: rgb(51, 57, 68) !important;
      }
      .logo-image:before {
          content: ' ';
          height: 45px;
          width: 45px;
      }
      &.full {
        max-width: 350px;
        min-width: 350px;
        height: 650px;
      }
      &.skyscraper {
        max-width: 160px;
        min-width: 160px;
        height: 600px;

        .text-container {
          padding-left: 6px;
          padding-right: 6px;
        }
        .headline1 {
          font-size: 26px;
          font-weight: 700;
          text-align: center;
          line-height: 39px;
        }
      }
      &.native {
        max-width: 480px;
        min-width: 480px;
        height: 120px;

        .text-container {
          padding-left: 6px;
        }
        .headline1, .headline2 {
          font-size: 16px;
          font-weight: 400;
          line-height: 18px;
        }
        .description1 {
          font-size: 12px;
          line-height: 14px;
        }
        .business-name {
          font-size: 10px;
          line-height: 12px;
        }
      }

      &.image {
        max-width: 300px;
        min-width: 300px;
        height: 250px;

        .business-name {
          font-size:17px;
          font-weight: 700;
        }
        .description1 {
          font-size: 11px;
          font-weight: 300;
          margin-right: 6px;
          margin-left: 6px;
        }
      }
      &.text {
        max-width: 300px;
        min-width: 300px;
        height: 250px;

        .headline1 {
          font-size: 20.7px;
          font-weight: 500;
          line-height: 26.91px;
        }
        .description1 {
          font-size: 15.6px;
          font-weight: 400;
          line-height: 20.28px;
          text-decoration-color: rgba(0,0,0,0.6);
        }
        .business-name {
          font-size: 14.5px;
          font-weight: 300;
          line-height: 21.75px;
          text-decoration-color: rgba(0,0,0,0.4);
        }
      }
    }

    .preview-img {
    }

    .logo-img {
      max-height: 64px;
    }

    .headline1 {
    }

    .description {
      font-size: 14px;
      font-weight: 300;
    }

    .arrow-link {
      .round {
        background-color: rgb(51, 57, 68);
        color: white;
      }
      .icon-lg {
        font-size: 1.25rem;
        line-height: 1;
      }

      .icon-md {
        font-size: 1rem;
        line-height: 1;
      }

      .icon-sm {
        font-size: .75rem;
        line-height: 1;
      }
    }

    .business-name {
      font-size: 20px;
      line-height: 28px;
      font-weight: 300;
    }

    .smaller {
      .business-name {
        font-size: 10px;
        line-height: 14px;
      }
    }
  }

  .ad-preview-item-facebook {
  }

  .ad-preview-item-shopping {
  }

  .ad-preview-item-unknown {
  }

  .extensions {
    ul.callouts {
      list-style: none;
      padding: 0;
      margin: 0;

      li:not(:first-child):before {
        content: ' · ';
      }
    }
  }
</style>
