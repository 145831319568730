<template>
  <div>
    <iframe v-if="postMessage"
            :id="urlToId(src)"
            :name="urlToId(src)"
            :src="src"
            class="border-0"
            :classes="classes"
            :width="width"
            :height="height"></iframe>
  </div>
</template>

<script>
import _ from 'lodash'

export default {
  name: 'iframeDisplayAdLoader',
  props: {
    id: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    src: {
      type: String,
      default: ''
    },
    classes: {
      type: String,
      default: ''
    },
    width: {
      type: Number,
      default: 100
    },
    height: {
      type: Number,
      default: 100
    },
    responseData: {
      type: Object,
      default: () => {
        return {}
      }
    },
    textAssets: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      // postMessage: null
    }
  },
  mounted () {
    window.addEventListener('message', this.sendDataToAd, false)
  },
  unmounted () {
    document.removeEventListener('message', this.sendDataToAd)
  },
  computed: {
    postMessage () {
      let msg = null

      if (!_.isEmpty(this.responseData)) {
        msg = _.cloneDeep(this.responseData.framePostBody)
      } else if (!_.isEmpty(this.textAssets)) {
        this.textAssets.framePostBody.forEach(n => {
          if (n.locationInAd === 'framePostBody') {
            msg = _.cloneDeep(n.text)
          }
        })
      }

      return msg
    }
  },
  methods: {
    urlToId (str) {
      return str.replace(/[^a-z0-9]/gi, '')
    },
    hasJsonStructure (str) {
      if (typeof str !== 'string') return false

      try {
        const result = JSON.parse(str)
        const type = Object.prototype.toString.call(result)
        return type === '[object Object]' || type === '[object Array]'
      } catch (err) {
        return false
      }
    },
    sendDataToAd (event) {
      if (this.postMessage && event.data && typeof event.data === 'string' && this.hasJsonStructure(event.data)) {
        let eventData = JSON.parse(event.data)

        // check for `message` && `source`
        if (eventData.source && eventData.message && eventData.message === 'sendData') {
          const key = this.urlToId(eventData.source)

          try {
            let el = document.getElementById(key).contentWindow

            let msg = this.postMessage.replace(/(\r\n|\n|\r)/gm, '')

            el.postMessage(msg, '*')
          } catch (e) {
            /* swallow message parse errors */
          }
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
