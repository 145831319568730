<template>
  <div>
    <template v-if="creativeType === 'WAZE_SEARCH_AD'">
      <div class="waze-ad search-ad cursor-default">
        <div class="d-flex">
          <div class="flex-grow-0 d-flex flex-column">
            <div class="flex-grow-1">
              <img :src="logoImagePath" class="logo">
            </div>
            <div class="flex-grow-0">
              <div class="tag">Ad</div>
            </div>
          </div>
          <div class="info flex-grow-1">
            <div class="title">{{locationName}}</div>
            <div class="text">{{address}}</div>
            <div class="text">{{city}}</div>
          </div>
          <div class="distance flex-grow-0 text-center">
            <div class="number">3.5</div>
            <div class="text">Mi away</div>
          </div>
        </div>
      </div>
    </template>
    <template v-else-if="creativeType === 'PIN_AD'">
      <div class="waze-ad pin-ad cursor-default">
        <div class="top"></div>
        <div class="middle">
          <div class="info-header position-relative">
            <div class="favorite">Favorite</div>
            <img :src="logoImagePath" class="logo">
            <section class="location-details">
              <p class="title text-center">{{locationName}}</p>
              <p class="address text-center">{{address}}, {{city}}</p>
              <p class="distance text-center">3.5 km away</p>
              <p class="open-now text-center"><span>Open now</span></p>
            </section>
          </div>
          <!-- TODO: make this section scroll -->
          <div class="info-scroll overflow-scroll-y">
            <div class="section pin-logo text-center"><img :src="bannerImagePath" class="img-fluid" /></div>
            <div v-if="cta.toLowerCase() !== 'drive there'" class="section pin-cta text-center">{{cta}}</div>
            <div class="section pin-info">{{additionalInfo}}</div>
            <div class="section legal-section">{{legal}}</div>
            <div class="section text-underline"><u>Read more about this advertiser</u></div>
            <div class="section pin-policy text-center"><u>Why am I seeing this ad?</u></div>

          </div>
        </div>
        <div class="bottom d-flex justify-content-center">
          <div class="icon px-3">
            <div class="phone"></div>
            <div class="text-center">Call</div>
          </div>
          <div class="icon px-3">
            <div class="pencil"></div>
            <div class="text-center">Edit</div>
          </div>
        </div>
      </div>
    </template>
    <template v-else-if="creativeType === 'TAKEOVER_AD'">
      <div class="waze-ad takeover-ad position-relative cursor-default">
        <div class="top d-flex">
          <div class="location flex-grow-1 hide-excess">{{address}}, {{city}}</div>
          <div class="open text-nowrap mt-3 mr-4"><span class="green-dot d-inline-block rounded-circle mr-2"></span>Open</div>
        </div>
        <div class="middle d-flex">
          <div class="flex-grow-0"><img class="banner" :src="logoImagePath"></div>
          <div class="flex-grow-1 text hide-excess">{{locationName}}</div>
        </div>
        <div class="bottom position-absolute d-flex w-100">
          <div class="flex-grow-0 mx-3">
            <div class="info-btn d-inline-block rounded-circle"></div>
          </div>
          <div class="flex-grow-1 mr-3">
            <div class="takeover-btn d-inline-block w-100 hide-excess">Drive There</div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import _ from 'lodash'

export default {
  name: 'adPreviewWaze',
  props: {
    creativePlan: {
      type: Object
    },
    previewType: {
      type: String,
      default: 'SAMPLE'
    }
  },
  data () {
    return {
    }
  },
  computed: {
    account () {
      return this.$store.getters.activeItem?.accountPlan ??
        this.$store.getters.account
    },
    locationName () {
      return this.account ? this.account.advertisedName ? this.account.advertisedName : this.account.name : 'Your location name'
    },
    adSpec () {
      return this.creativePlan ? this.creativePlan.adSpec : {}
    },
    creativeType () {
      return this.creativePlan ? this.creativePlan.creativeType : 'UNKNOWN'
    },
    address () {
      return this.account ? this.account.address1 + (this.account.address2 ? this.account.address2 : '') : 'Your address'
    },
    city () {
      return this.account ? this.account.city : 'your city'
    },
    cta () {
      switch (this.textAsset('callToAction')) {
        case 'DRIVE_THERE':
          return 'Drive There'
        case 'CALL':
          return 'Call Now'
        case 'SAVE_LOCATION':
          return 'Save for Later'
        case 'REMINDER':
          return 'Save Location'
      }
      return ''
    },
    logoImagePath () {
      return this.imageAsset('iconImageVerified')
    },
    bannerImagePath () {
      return this.imageAsset('bannerImageVerified')
    },
    legal () {
      return this.textAsset('legalInfo')
    },
    additionalInfo () {
      return this.textAsset('moreInfo')
    }
  },
  methods: {
    textAsset (locationIdAd) {
      if (this.adSpec.textAssets) {
        let txts = this.adSpec.textAssets.filter(t => t.locationInAd.includes(locationIdAd))
        if (txts.length > 0) {
          return txts[_.random(txts.length - 1)].text
        }
      }
      return ''
    },
    imageAsset (locationIdAd) {
      if (this.adSpec.imageAssets) {
        let imgs = this.adSpec.imageAssets.filter(t => t.locationInAd.includes(locationIdAd))
        if (imgs.length > 0) {
          return imgs[_.random(imgs.length - 1)].path
        }
      }
      return ''
    }
  }
}
</script>

<style lang="scss" scoped>
  .hide-excess {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .waze-ad {
    width: 320px;
    box-sizing: content-box;
    background-color: white;
    border: 3px solid #e9e9e9;
  }

  .search-ad {
    img {
      border-radius: 3px;
      margin: 16px;
      width: 30px;
    }
    .tag {
      color: #f88885;
      text-align: center;
      font-size: 14px;
    }
    .info {
      margin: 10px 0;

      .title {
        max-width: 180px;
        margin-bottom: 2px;
        font-family: 'AvenirNext-Bold', 'HelveticaNeue-Bold', sans-serif, arial;
        font-weight: bold;
        color: #4c4c4c;
        font-size: 17px;
      }
      .text {
        max-width: 180px;
        font-family: 'AvenirNext-Light', 'HelveticaNeue-Light', sans-serif, arial;
        font-weight: 100;
        color: #989797;
        font-size: 13px;
      }
    }
    .distance {
      margin: 13px 0;
      padding: 14px;
      border-left: 1px solid #d1dcdf;
      color: #58bfdf;
      font-family: 'AvenirNext-Medium', 'HelveticaNeue-Medium', sans-serif, arial;
      font-weight: 300;

      .number {
        font-size: 21px;
        line-height: 21px;
      }
      .text {
        font-size: 9px;
        line-height: 9px;
      }
    }
  }

  .takeover-ad {
    height: 243px;
    color: white;
    background: linear-gradient(to top, transparent, transparent 46px, #2d3031 48px, #2d3031);

    .top {
      .location {
        font-family: AvenirNext-Regular,HelveticaNeue,Helvetica Neue,sans-serif,arial;
        font-weight: 300;
        font-size: 14px;
        height: 30px;
        line-height: 30px;
        margin: 0 50px 0 5px;
      }
      .open {
        font-family: AvenirNextCondensed-DemiBold,HelveticaNeue-Bold,Helvetica Neue,sans-serif,arial;
        font-size: 10px;
        font-weight: 700;
        text-transform: uppercase;
      }
      .green-dot {
        background-color: lime;
        height: 10px;
        width: 10px;
      }
    }
    .middle {
      margin: 24px 8px 8px;

      .banner {
        width: 87px;
        height: 87px;
        background-color: #fff;
        border: 3px solid #fff;
        border-radius: 2px;
      }
      .text {
        font-family: -apple-system,system-ui, -apple-system, Segoe UI, Roboto, Ubuntu, Cantarell, Noto Sans, sans-serif,BlinkMacSystemFont,Segoe UI,Roboto,Ubuntu,“Helvetica Neue”,sans-serif,Arial;
        font-weight: 700;
        margin-left: 10px;
        letter-spacing: .5px;
        font-size: 22px;
        line-height: 30px;
        height: 30px;
      }
    }
    .bottom {
      bottom: 20px;

      .info-btn {
        width: 45px;
        height: 45px;
        background: url('../../../apps/backpack/src/assets/img/waze-icon-i.png') white no-repeat 4px 4px;
        box-shadow: 0 2px 6px rgba(0,0,0,.5);
      }
      .takeover-btn {
        height: 45px;
        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.5);
        text-transform: uppercase;
        background-color: #02b1e2;
        line-height: 45px;
        text-align: center;
        font-size: 16px;
        font-family: -apple-system,system-ui,BlinkMacSystemFont,Segoe UI,Roboto,Ubuntu,“Helvetica Neue”,sans-serif,Arial;
        font-weight: 700;
        border-radius: 25px;
      }
    }
  }

  .pin-ad {
    .top {
      height: 215px;
      background: url('../../../apps/backpack/src/assets/img/waze-icon-pin-bg.png') white no-repeat 0 0;
    }
    .info-header {
      min-height: 100px;
      border-bottom: 1px solid #d1dcdf;
    }
    .favorite {
      position: absolute;
      top: -35px;
      left: 35px;
      font-family: 'AvenirNext-Medium', 'HelveticaNeue-Medium', sans-serif, arial;
      font-size: 16px;
      font-weight: 300;
    }
    .logo {
      position: absolute;
      left: 50%;
      top: -70px;
      width: 60px;
      margin-left: -30px;
      -webkit-box-shadow: 0 2px 10px rgba(0,0,0,0.3);
      box-shadow: 0 2px 10px rgba(0,0,0,0.3);
      background-color: #fff;
      border: 3px solid #fff;
      border-radius: 5px;
      max-height: 60px;
      overflow: hidden;
    }

    .location-details {
      margin: 0 13px;
      padding-bottom: 10px;

      p {
        margin: 0 0 10px;
      }
      .title {
        font-family: 'AvenirNext-Bold', 'HelveticaNeue-Bold', sans-serif, arial;
        font-weight: bold;
        font-size: 20px;
      }
      .address {
        font-size: 16px;
        font-family: 'AvenirNext-Medium', 'HelveticaNeue-Medium', sans-serif, arial;
        font-weight: 300;
        color: #989797;
        margin-bottom: 0;
      }
      .distance {
        font-size: 16px;
        font-family: 'AvenirNext-Light', 'HelveticaNeue-Light', sans-serif, arial;
        font-weight: 100;
      }
      .open-now {
        font-size: 16px;
        font-family: 'AvenirNext-Regular', 'HelveticaNeue', sans-serif, arial;
        font-weight: 600;

        span {
          display: inline-block;
          padding: 2px 10px;
          border-radius: 25px;
          background-color: #20da9c;
          color: #fff;
        }
      }
    }
    .info-scroll {
      height: 236px;
      padding: 10px;
      color: #777;

      .section {
        font-size: 15px;
        line-height: 18px;
        padding: 5px 5px 15px 5px;
      }

      .pin-logo {
        margin-bottom: 10px;
      }
      .pin-cta {
        font-family: AvenirNext-DemiBold,HelveticaNeue-Bold,Helvetica Neue,sans-serif,arial;
        font-weight: 700;
        line-height: 52px;
        font-size: 18px;
        background-color: #fff;
        color: #777;
        box-shadow: 0 0 6px rgba(0,0,0,.3);
        border-radius: 8px;
        margin-bottom: 10px;
        padding: 0;
      }
      .pin-policy {
        margin-top: 20px;
      }
    }

    .bottom {
      border-top: 1px solid #d1dcdf;
      margin-top: 13px;
      padding: 10px 0 10px 0;

      .icon {
        font-family: 'AvenirNext-Medium', 'HelveticaNeue-Medium', sans-serif, arial;
        font-weight: 300;
        color: #989797;
        font-size: 15px;
      }

      .phone,
      .pencil {
        width: 36px;
        height: 36px;
      }
      .phone {
        background: url('../../../apps/backpack/src/assets/img/waze-icon-phone.png') white no-repeat 0 0;
      }
      .pencil {
        background: url('../../../apps/backpack/src/assets/img/waze-icon-pencil.png') white no-repeat 0 0;
      }
    }
  }
</style>
