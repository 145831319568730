export const getImageSourceFromYouTubeVideo = (path) => {
  // h/t - http://www.get-youtube-thumbnail.com/
  if (path) {
    const idExtract = path.match(/(?:https?:\/{2})?(?:w{3}\.)?youtu(?:be)?\.(?:com|be)(?:\/watch\?v=|\/)([^\s&]+)/)
    if (!idExtract) return ''
    const thumb = `https://i3.ytimg.com/vi/${idExtract[1]}/hqdefault.jpg`
    return thumb
  }
  return null
}
