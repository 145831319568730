<template>
  <div v-if="effectivePreviewType === 'SMALL'" class="d-flex align-items-center">
    <div class="position-relative" style="flex: 0 0 46px">
      <img :src="previewImgSrc"
           loading="lazy"
           class="img-thumbnail img-square-thumbnail fallback"
           alt="preview"/>
      <div v-if="creativeType === 'VIDEO_AD'" class="overlay">
        <fluency-icon type="caretRight" class="video-indicator"/>
      </div>
    </div>
    <span class="pl-2 text-truncate"
          v-p-tooltip.top="name">{{ name }}</span>
  </div>
  <div v-else-if="effectivePreviewType === 'DETAILED'" class="d-flex flex-column">
    <b-button-group v-if="previewAssetsForDetail.length > 1 && !reportMode" class="align-self-start btn-group-xs pb-1">
      <b-button size="xs"
                :disabled="previewAssetsForDetail[0].translate === 0"
                @click="previewAssetsForDetail.forEach(d => d.translate += 100)">
        <fluency-icon type="caretLeft"/>
      </b-button>
      <b-button size="xs"
                :disabled="previewAssetsForDetail[previewAssetsForDetail.length - 1].translate === (previewAssetsForDetail.length - 1) * -100"
                @click="previewAssetsForDetail.forEach(d => d.translate -= 100)">
        <fluency-icon type="caretRight"/>
      </b-button>

    </b-button-group>
    <div class="d-flex overflow-hidden-x">
      <div v-for="(previewAsset,index) in previewAssetsForDetail" class="d-flex align-items-start"
           style="flex: 0 0 65%; transition: transform ease 250ms;"
           :style="'transform: translateX(' + previewAsset.translate + '%);'" :key="index" ref="mover">
        <div class="position-relative" style="flex: 0 0 46px">
          <img :src="swapFallback(previewAsset.path) "
               class="img-thumbnail img-square-thumbnail fallback"
               loading="lazy"
               alt="preview"/>
          <div v-if="creativeType === 'VIDEO_AD'" class="overlay">
            <fluency-icon type="caretRight" class="video-indicator"/>
          </div>
        </div>
        <div class="d-flex flex-wrap flex-static-cols">
          <strong class="flex-1x-item" :class="previewAsset.titleHighlight">{{ previewAsset.title }}</strong>
          <span class="text-muted flex-2x-item"
                :class="previewAsset.descriptionHighlight">{{ previewAsset.description }}</span>
          <a class="flex-3x-item" :href="previewAsset.url" :class="previewAsset.ctaHighlight" target="_blank">
            {{ $filters.convertFromCapsUnderscore(filterizeCta(previewAsset.cta))}}
          </a>
        </div>
      </div>
    </div>
    <div class="mt-2" style="flex: 0 0 100%" :class="highlightMap['body'] || ''">{{ body }}</div>
  </div>
  <div v-else-if="effectivePreviewType === 'SAMPLE'" :class="{ 'report-mode': reportMode }">
    <t-alert class="m-0 p-2 mr-3 mb-3" size="sm" variant="info" v-if="hasPinnedAsset" show><fluency-icon type="info"/>
      Broadcast Messages are Active
    </t-alert>
    <div v-if="previewAssetList.length > 1 && creativeType !== 'COLLECTION_AD' && !reportMode">
      <b-button-group size="sm" class="">
        <b-button :disabled="previewIndex === 0" class="p-1" variant="link" @click="setPreviewIndex(previewIndex - 1)">
          <fluency-icon type="chevronLeft"/>
        </b-button>
        <span class="mx-2 preview-switch-text">Preview {{previewIndex + 1}} / {{previewAssetList.length}}</span>
        <b-button :disabled="previewIndex === previewAssetList.length - 1" class="p-1" variant="link" @click="setPreviewIndex(previewIndex + 1)">
          <fluency-icon type="chevronRight"/>
        </b-button>
      </b-button-group>
    </div>
    <div v-if="creativeType === 'COLLECTION_AD'" class="collection-ad-wrapper">
<!--      <b-button v-if="!reportMode" variant="link" @click="previewPostClick = !previewPostClick" class="py-0">Switch preview</b-button>-->
      <div class="collection-ad-container"
           @click="previewPostClick = !previewPostClick">
        <header v-if="!previewPostClick" class="top d-flex pt-2 align-items-center">
          <div class="logo">
            <img :src="partnerPagePhoto" width="47" height="47" />
          </div>
          <div class="name-wrapper">
            <div class="name-container">
              <span class="name">{{ (demoOverrides && demoOverrides.partnerPageName) ? demoOverrides.partnerPageName : partnerPageName }}</span>
              <span class="sponsored">Sponsored</span>
            </div>
          </div>
        </header>
        <div v-if="!previewPostClick" class="body-wrapper">
          <div class="body-container" :class="{'see-more': showSeeMoreBodyLink}" ref="bodyContainer">
            <div v-if="showSeeMoreBodyLink" class="body-spacer"></div>
            <div v-if="showSeeMoreBodyLink" class="body-see-more">
              <b-button variant="link" class="p-0" @click.stop="showSeeMoreBodyLink = false">...See More</b-button>
            </div>
            <div class="body-text">
              <span>{{ body }}</span>
            </div>
          </div>
        </div>

        <div class="preview-elements">
          <div v-if="!(creativePlan.instantExperienceTemplate && previewPostClick)" class="preview-media-container">
              <img v-if="isImage(videoSrc)"
                   :src="videoSrc"
                   class="preview-media"
                   loading="lazy" />
              <video v-else-if="isVideo(videoSrc)"
                     class="preview-media"
                     :src="videoSrc"
                     :poster="videoThumbnailAssetSrc"
                     preload="metadata"
                     controls />
              <img v-else-if="!primaryImageAssetSrc || isImage(primaryImageAssetSrc)"
                   :src="primaryImageAssetSrc || imagePlaceholder"
                   class="preview-media"
                   loading="lazy" />
<!--            TODO add "swipe up to view"-->
            </div>
          <div v-if="previewPostClick && creativePlan.instantExperienceTemplate">
            <instant-experience-local-preview :elements-json="creativePlan.instantExperienceTemplate.elementsJson"
                                              :background-color="creativePlan.instantExperienceTemplate.backgroundColor"
            />
          </div>
          <div v-else-if="previewPostClick" class="preview-products-container">
<!--            loop through products here-->
            <div v-for="(previewAsset, previewAssetIndex) of additionalImageAssets.filter(ia => imageCheck[ia.path])"
                 :key="previewAssetIndex"
                 class="preview-product">
              <a :href="getTextAssetsForCarouselItem(previewAsset, 'website_url') || '#'" target="_blank">
                <img :src="swapFallback(previewAsset.path)" loading="lazy" />
                <div v-if="overlayTemplateObject.enabled" class="overlay-template-container"
                     :class="[overlayTemplateObject.position, `${overlayTemplateObject.shape}-container`]">
                  <div class="overlay-shape"
                       :class="[overlayTemplateObject.shape, overlayTemplateObject.position]"
                       :style="`background-color: #${overlayTemplateObject.themeBackgroundColor}; color: #${overlayTemplateObject.themeFontColor}; opacity: ${overlayTemplateObject.opacity};`">
                    <span :style="`font-family: ${overlayTemplateObject.fontFamily}; font-size: ${overlayTemplateObject.fontSize}; font-weight: ${overlayTemplateObject.fontWeight}`">
                      {{overlayLengthLimit(getTextAssetsForCarouselItem(previewAsset, 'samplePrice'), overlayTemplateObject.shape) }}
                    </span>
                  </div>
                </div>
              </a>

              <div class="product-text">
                <div class="product-headline">{{ getTextAssetsForCarouselItem(previewAsset, 'productHeadline') }}</div>
                <div class="product-description">{{ getTextAssetsForCarouselItem(previewAsset, 'productDescription') }}</div>
              </div>
            </div>
          </div>
          <div v-if="previewPostClick && !creativePlan.instantExperienceTemplate" class="see-more-container">
            <div class="mt-4">
              <fluency-icon type="chevronUp" />
            </div>
            <div class="see-more-text">
              Swipe to Load Website
            </div>
          </div>
          <div v-if="!previewPostClick && title" class="headline-wrapper">
            <div class="headline-container">
              <div class="headline">
                {{title}}
              </div>
            </div>
          </div>
<!--          <header v-if="!previewPostClick" class="p-3 bg-light">-->
<!--            <div class="title overflow-hidden text-overflow-ellipsis " style="min-height: 14px">{{ title }}</div>-->
<!--          </header>-->
          <div v-if="!previewPostClick && !reportMode" class="preview-product-thumbnails-wrapper">
            <div v-for="(previewAsset, previewAssetIndex) of collectionAdPreviewBarAssets"
                 :key="previewAssetIndex"
                 class="preview-product-thumbnail-container"
                  :class="{'preview-product-thumbnail-overlay-container' : previewAssetIndex === collectionAdPreviewBarAssets.length - 1}">
              <div v-if="previewAssetIndex === collectionAdPreviewBarAssets.length - 1" class="preview-product-thumbnail-overlay">
                More
              </div>
              <img :src="swapFallback(previewAsset.path)"
                   class="preview-product-thumbnail"
                   loading="lazy"
                   :height="`${308.01 / collectionAdPreviewBarAssets.length }`"
                   :width="`${314.01 / collectionAdPreviewBarAssets.length }`" />
            </div>
          </div>
        </div>
        <div v-if="previewPostClick" class="footer-wrapper">
          <div class="footer-container">
            <a class="footer-button" :href="targetUrl || '#'">
              <div class="footer-text">{{ footerLabel }}</div>
            </a>
          </div>
        </div>
      </div>

    </div>

<!--    Start new preview here-->
    <div v-else-if="creativeType !== 'COLLECTION_AD'" class="standard-ad-wrapper" @click="previewPostClick = !previewPostClick">
      <div v-if="previewPostClick && instantExperiencePreview">
        <instant-experience-local-preview :elements-json="instantExperiencePreview.elementsJson"
                                          :background-color="instantExperiencePreview.backgroundColor" />
      </div>
      <div v-else class="standard-ad-container">
        <header>
          <div class="logo">
            <img :src="partnerPagePhoto" width="47" height="47" />
          </div>
          <div class="name-wrapper">
            <div class="name-container">
              <span class="name">{{ (demoOverrides && demoOverrides.partnerPageName) ? demoOverrides.partnerPageName : partnerPageName }}</span>
              <span class="sponsored">Sponsored</span>
            </div>
          </div>

        </header>
        <div class="body-wrapper">
          <div class="body-container" :class="{'see-more': showSeeMoreBodyLink}" ref="bodyContainer">
            <div v-if="showSeeMoreBodyLink" class="body-spacer"></div>
            <div v-if="showSeeMoreBodyLink" class="body-see-more">
              <b-button variant="link" class="p-0 see-more-link" @click.stop="showSeeMoreBodyLink = false">...See More</b-button>
            </div>
            <div class="body-text">
              <span>{{ body }}</span>
            </div>
          </div>
        </div>
        <div class="media-footer-wrapper">
          <div v-if="creativeType === 'CAROUSEL_AD'" class="carousel-wrapper">
            <div class="carousel-container">
              <div class="scrollable-carousel-frame">
                <div class="scrollable-carousel-window">
                  <ul class="scrollable-carousel-list" ref="carouselContainer"
                        style="transform: translateX(0px);">
                    <li  v-for="(carouselItem, carouselIndex) of carouselMedia"
                         :key="carouselIndex"
                         class="position-relative"
                        :data-carousel-index="carouselIndex">
                      <div class="carousel-card">
                        <div class="carousel-card-media-container">
                          <template v-if="carouselItem.type === 'video'">
                            <img v-if="isImage(carouselItem.path)"
                                 :src="carouselItem.path"
                                 loading="lazy" height="230" width="230" class="carousel-card-media" />
                            <video v-else-if="isVideo(carouselItem.path)"
                                   class="carousel-card-media"
                                   height="230" width="230"
                                   :src="carouselItem.path"
                                   :poster="carouselItem.thumbnailImage ? carouselItem.thumbnailImage.path : null"
                                   preload="metadata"
                                   controls />
                            <div v-if="shouldShowPreviewOverlay(carouselItem, carouselIndex)" class="overlay">
                              <span class="preview-image-indicator"><fluency-icon type="preview" /> PREVIEW</span>
                            </div>
                          </template>
                          <div v-else-if="carouselItem.type === 'map'" class="carousel-card-media">
                            <static-google-map v-if="accountPlan"
                                               :address="accountPlan"
                                               :center-label="accountName"
                                               :lat="accountPlan.latitude"
                                               :lng="accountPlan.longitude"
                                               center-marker
                                               size="230x230"
                                               zoom="12" />
                          </div>
                          <template v-else-if="carouselItem.type === 'image'">
                          <img class="carousel-card-media"
                               :src="swapFallback(carouselItem.path)"
                               height="230" width="230"
                               loading="lazy" />
                            <div v-if="shouldShowPreviewOverlay(carouselItem, carouselIndex)" class="overlay">
                              <span class="preview-image-indicator"><fluency-icon type="preview" /> PREVIEW</span>
                            </div>
                          </template>
                          <a v-else-if="carouselItem.type === 'endCard'"
                              :href="carouselEndCardSeeMoreUrl"
                              target="_blank">
                            <img :src="partnerPagePhoto"
                                 class="carousel-card-media"
                                 height="230" width="230" />
                          </a>
                          <div v-if="frameImageObject.enabled"
                               class="frame-image-container"
                               :class="[frameImageObject.position, `blend-${frameImageObject.blendingMode}`]">
                            <img :src="frameImageObject.src"
                                 :style="`opacity: ${frameImageObject.opacity}; max-height: ${frameImageObject.scale}%; max-width: ${frameImageObject.scale}%;`"/>
                          </div>
                          <div v-if="overlayTemplateObject.enabled" class="overlay-template-container"
                               :class="[overlayTemplateObject.position, `${overlayTemplateObject.shape}-container`]">
                            <div class="overlay-shape"
                                 :class="[overlayTemplateObject.shape, overlayTemplateObject.position]"
                                 :style="`background-color: #${overlayTemplateObject.themeBackgroundColor}; color: #${overlayTemplateObject.themeFontColor}; opacity: ${overlayTemplateObject.opacity};`">
                              <span :style="`font-family: ${overlayTemplateObject.fontFamily}; font-size: ${overlayTemplateObject.fontSize}; font-weight: ${overlayTemplateObject.fontWeight}`">
                                {{overlayLengthLimit(getTextAssetsForCarouselItem(carouselItem, 'samplePrice'), overlayTemplateObject.shape) }}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div class="carousel-card-footer-container">
                          <div class="carousel-card-footer-label" :class="{'invisible': carouselItem.type === 'map'}">
                            <div class="carousel-card-footer-headline-container">
                              <div class="d-flex flex-column">
                                <div class="carousel-card-footer-headline">
                                  <span v-if="carouselItem.type === 'endCard'">
                                    See more <span v-if="displayUrl">at {{displayUrl}}</span>
                                  </span>
                                  <span v-else>{{getTextAssetsForCarouselItem(carouselItem, 'title') || accountName}}</span>
                                </div>
                              </div>
                            </div>
                            <span class="carousel-card-description">
                              {{getTextAssetsForCarouselItem(carouselItem, 'description')}}
                            </span>
                          </div>
                          <div class="carousel-card-cta-button-container">
                            <a class="carousel-card-cta-button" :href="getTextAssetsForCarouselItem(carouselItem, 'website_url')" target="_blank">
                              <div class="carousel-card-cta-text">
                                <span> {{ $filters.convertFromCapsUnderscore(filterizeCta(getTextAssetsForCarouselItem(carouselItem, 'callToAction')))}}</span>
                              </div>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div class="carousel-card-scrim-container" v-if="carouselIndex !== previewIndex">
                        <div class="carousel-card-advancer" role="button" @click="setPreviewIndex(carouselIndex)">
                          <div class="carousel-card-scrim"></div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="position-relative">
            <div class="media-footer-border"></div>
            <div class="image-container position-relative">
              <!--              Check videos first-->
              <div v-if="creativeType === 'SLIDESHOW_AD'"> <!-- need position absolute here? -->
                                                           <!--                  list through the images. inside of an absolute box. images on top of each other
                                                                                 maintain index of current item? fade out opactiy of one, fade in opacity of next-->
                <img v-for="(imageUrl, index) of slideshowAdMedia.imageUrls" :key="index"
                     ref="slideshowImage"
                     :src="imageUrl"
                     width="320" height="320"
                     class="preview-media top-0 left-0 position-absolute"
                     :style="`opacity: ${slideshow.currentIndex === index ? '1' : '0'}; transition: opacity ${slideshow.transitionDuration}ms linear`"/>
              </div>
              <img v-else-if="isImage(videoSrc)"
                   :src="videoSrc"
                   width="320"
                   class="preview-media"
                   loading="lazy"/>
              <video v-else-if="isVideo(videoSrc)"
                     class="preview-media"
                     :src="videoSrc"
                     width="320"
                     :poster="videoThumbnailAssetSrc"
                     preload="metadata"
                     controls/>
              <img v-else
                   :src="swapFallback(previewImgSrc)"
                   width="320"
                   class="preview-media"
                   loading="lazy"/>
              <div v-if="overlayTemplateObject.enabled" class="overlay-template-container"
                   :class="[overlayTemplateObject.position, `${overlayTemplateObject.shape}-container`]">
                <div class="overlay-shape"
                     :class="[overlayTemplateObject.shape, overlayTemplateObject.position]"
                     :style="`background-color: #${overlayTemplateObject.themeBackgroundColor}; color: #${overlayTemplateObject.themeFontColor}; opacity: ${overlayTemplateObject.opacity};`">
                  <span :style="`font-family: ${overlayTemplateObject.fontFamily}; font-size: ${overlayTemplateObject.fontSize}; font-weight: ${overlayTemplateObject.fontWeight}`">
                    {{overlayLengthLimit(getTextAssetsForCarouselItem(previewAsset, 'samplePrice'), overlayTemplateObject.shape) }}
                  </span>
                </div>
              </div>
              <div v-if="shouldShowPreviewOverlay(previewAsset, 0)" class="overlay">
                <span class="preview-image-indicator"><fluency-icon type="preview" /> PREVIEW</span>
              </div>
            </div>
            <div class="footer-container">
              <div class="link-title-container">
                <div>
                  <div class="display-link-container">
                    <span class="display-link">{{ displayUrl }}</span>
                  </div>
                  <div class="title-container">
                    <div class="title">
                      <span>{{ title }}</span>
                    </div>
                  </div>
                </div>
                <div class="description-container">
                  <span class="description">{{ description }}</span>
                </div>
              </div>
              <div class="cta-button-container">
                <a class="cta-button" :href="targetUrl" target="_blank">
                  <div class="cta-text">
                    <span> {{ $filters.convertFromCapsUnderscore(filterizeCta(cta))}}</span>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import { isImage, isVideo, doesImageExist } from 'core-ui/utils/mediaUtils'
import imagePlaceholder from 'core-ui/assets/img/image-placeholder-300x300.svg'
import { reactive, defineAsyncComponent } from 'vue'

const MEDIA_DATA_AD = 'MEDIA_DATA_AD'
const priceOverlayTextLengthLimits = {
  rectangle: 13,
  pill: 13,
  triangle: 6,
  circle: 6
}

export default {
  name: 'adPreviewFacebook',
  components: {
    StaticGoogleMap: defineAsyncComponent(() => import('core-ui/components/common/staticGoogleMap')),
    InstantExperienceLocalPreview: defineAsyncComponent(() => import('core-ui/components/adPreviews/instantExperienceLocalPreview'))
  },
  props: {
    creativePlan: {
      type: Object
    },
    previewType: {
      type: String,
      default: 'SAMPLE',
      validator: function (val) {
        return ['SMALL', 'SAMPLE', 'DETAILED'].indexOf(val) !== -1
      }
    },
    highlightMap: { // a map of locationInAd and a bootstrap outline variant class to highlight
      type: Object
    },
    demoOverrides: { // overrides for Demo purposes. In practice this would require renaming your page within Facebook
      type: Object,
      default: null
    },
    previewAssetIndex: {
      type: Number,
      default: 0
    },
    reportMode: {
      type: Boolean,
      default: false
    },
    showCustomizationOptions: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      preview: null,
      localPreview: null,
      previewIndex: this.previewAssetIndex,
      previewPostClick: false,
      showSeeMoreBodyLink: true,
      imageCheck: {
        fallback: true
      },
      imagePlaceholder,
      slideshow: {
        imageUrls: [],
        transitionDuration: 250,
        frameDuration: 1750,
        currentInterval: null,
        currentIndex: 0
      },
      instantExperiencePreview: null
    }
  },
  mounted () {
    this.$eventBus.$on('adPreviewFacebook::refreshSamplePreview', this.loadSamplePreview)
  },
  beforeUnmount () {
    this.$eventBus.$off('adPreviewFacebook::refreshSamplePreview', this.loadSamplePreview)
    this.clearSlideshowInterval()
  },
  watch: {
    'creativePlan.creativePlanId': {
      handler (val, oldVal) {
        if (val !== oldVal) {
          this.clearSlideshowInterval()
          this.localPreview = null
        }

        const validCreativeTypes = ['DYNAMIC_PRODUCT_AD', 'PROMOTED_POST', 'COLLECTION_AD']
        if (!val || (val && (!oldVal || val !== oldVal) && this.previewType === 'SAMPLE' && validCreativeTypes.includes(this.creativePlan?.creativeType))) {
          this.loadDynamicPreview()
        }
      },
      immediate: true
    },
    'previewAssetList.length': {
      immediate: true,
      handler (newVal) {
        if (newVal === 0) {
          this.setPreviewIndex(0)
        } else if (this.previewIndex + 1 > newVal) {
          this.setPreviewIndex(newVal - 1)
        }
      }
    },
    previewAssetIndex: {
      immediate: true,
      handler (newVal) {
        if (newVal >= 0) {
          this.setPreviewIndex(newVal)
        }
      }
    },
    facebookPageId: {
      immediate: true,
      handler: function (newVal, oldVal) {
        if (newVal && newVal !== oldVal) {
          if (!this.$store.getters.partnerPages[newVal] && this.user?.userRegistryId) {
            this.$store.dispatch('fetchPartnerPages', {
              accountPlanId: null,
              facebookPageId: newVal
            })
          }
        }
      }
    },
    instantExperienceId: {
      immediate: true,
      async handler (newVal) {
        // attempt to find the instant experience, async get it if needed
        if (newVal) {
          const vuexObj = this.$store.getters.accountPageInstantExperiences
          if (vuexObj.instantExperiences?.length > 0) {
            // eslint-disable-next-line eqeqeq
            const found = vuexObj.instantExperiences.find(ie => ie.instantExperienceId == newVal) // newVal might be string or integer
            if (found) {
              this.$setCompat(this, 'instantExperiencePreview', found)
              return
            }
          }

          // couldn't find it in vuex, fetch it
          if (this.$authn.isAuthenticated()) {
            const results = await this.$res.fetch.instantExperiences({ instantExperienceId: newVal })
            if (results?.length > 0) {
              this.$setCompat(this, 'instantExperiencePreview', results[0])
              return
            }
          }
        }
        this.instantExperiencePreview = null
      }
    }
  },
  computed: {
    name () {
      return this.creativePlan ? this.creativePlan.name : ''
    },
    adSpec () {
      if (!this.creativePlan) {
        return {}
      }
      return this.localPreview || this.creativePlan.previewAdSpec || this.creativePlan.adSpec || {}
    },
    user () {
      return this.$store.getters.user
    },
    creativeType () {
      if (!this.creativePlan) {
        return 'UNKNOWN'
      }
      const type = this.creativePlan.creativeType
      if (type === 'DYNAMIC_PRODUCT_AD') {
        const forceSingleImage = this.adSpec?.textAssets?.find(e => e.locationInAd === 'forceSingleImage')?.text === 'true'
        const forceSingleProduct = this.adSpec?.textAssets?.find(e => e.locationInAd === 'forceSingleProduct')?.text === 'true'

        if (forceSingleImage || (forceSingleProduct && this.adSpec?.imageAssets?.length < 3)) {
          return 'IMAGE_AD'
        } else {
          return 'CAROUSEL_AD'
        }
      }
      return type
    },
    accountPlan () {
      if (this.creativePlan?.accountPlan) {
        return this.creativePlan.accountPlan
      }
      const item = this.$store.getters.settingsItem || this.$store.getters.activeItem
      if (item && this.$store.getters.accountsById && item.accountPlanId) {
        const account = this.$store.getters.accountsById.get(item.accountPlanId)
        if (account) {
          return account
        }
      }
      if (this.$store.getters.account && this.$store.getters.account.hasOwnProperty('accountPlanId')) {
        return this.$store.getters.account
      }
      if (this.$store.state.createFlow && this.$store.state.createFlow?.accountPlan?.accountPlanId === this.creativePlan?.accountPlanId) {
        return this.$store.state.createFlow.accountPlan
      }
      return null
    },
    facebookPageId () {
      const adPageOverride = this.adSpec?.textAssets?.find(ta => ta.locationInAd === 'promotedPageId')
      let pageId = ''
      if (adPageOverride && adPageOverride.text?.length > 0) {
        pageId = adPageOverride.text
        // TODO need some way to campaign level promoted page
      } else if (this.accountPlan?.facebookPageId?.length > 0) {
        pageId = this.accountPlan.facebookPageId
      }
      return pageId
    },
    partnerPage () {
      const pageId = this.facebookPageId
      if (pageId?.length > 0 && this.user?.userRegistryId) {
        return this.$store.getters.partnerPages?.[pageId] ?? null
      }
      return null
    },
    partnerPageName () {
      return this.partnerPage?.name ?? this.accountName
    },
    partnerPagePhoto () {
      return this.partnerPage?.profilePhoto ||
          this.partnerPage?.coverPhoto ||
          this.adSpec?.imageAssets?.find(im => im.locationInAd === 'partnerPageCover')?.path ||
          this.imagePlaceholder
    },
    accountName () {
      return this.accountPlan?.advertisedName || this.accountPlan?.name || 'Account Name'
    },
    effectivePreviewType () {
      return this.previewType
    },
    videoSrc () {
      if (this.adSpec.videoAssets) {
        const videos = this.adSpec.videoAssets
        if (videos.length > 0) {
          return videos[this.previewIndex].path
        }
      }
      return ''
    },
    videoThumbnailAssetSrc () {
      if (this.adSpec.videoAssets) {
        if (this.adSpec.videoAssets.length > 0) {
          const thumbnail = this.adSpec.videoAssets[this.previewIndex].thumbnailImage
          if (thumbnail) {
            return thumbnail.path
          }
        }
      }
      return ''
    },
    primaryImageAsset () {
      const primary = this.adSpec.imageAssets?.find(t => t.locationInAd?.includes('imageUrl'))
      if (primary) {
        return primary
      }
      if (this.previewAssetList.length > 0) {
        return this.previewAssetList[0]
      }
      return null
    },
    primaryImageAssetSrc () {
      return this.primaryImageAsset?.path ?? null
    },
    additionalImageAssets () {
      const images = this.adSpec.imageAssets
        ?.filter(t => t.locationInAd?.includes('additionalImages')) ?? []
      return images
    },
    instantExperienceId () {
      const asset = this.adSpec.textAssets?.find(t => t.locationInAd?.includes('instantExperienceId')) ?? null
      if (asset) {
        const id = parseInt(asset.text)
        if (!isNaN(id)) {
          return id
        }
      }
      return null
    },
    collectionAdThumbnailAssets () {
      const thumbs = this.adSpec.imageAssets
        ?.filter(ia => ia.locationInAd?.includes('collectionThumbnails')) ?? []
      return thumbs
    },
    collectionAdPreviewBarAssets () {
      // look for collection Thumbnails first
      const assets = []
      if (this.collectionAdThumbnailAssets.length > 0) {
        assets.push(...this.collectionAdThumbnailAssets)
      } else {
        assets.push(...this.additionalImageAssets)
      }

      const needChecks = assets.filter(ia => !this.imageCheck.hasOwnProperty(ia.path)).map(ia => ia.path)
      if (needChecks.length > 0) {
        this.doImageCheck(needChecks)
      }
      const validChecks = assets.filter(ia => this.imageCheck[ia.path] === true)
      if (this.collectionAdThumbnailAssets.length > 0) {
        return validChecks.slice(0, 4)
      } else {
        // dynamic product set collection ad
        return validChecks.slice(0, 3)
      }
    },
    previewAssetList () {
      const carouselMedia = this.carouselMedia
      if (carouselMedia.length > 0) {
        return carouselMedia
      }
      if (this.adSpec.imageAssets?.length > 0) {
        let images = this.adSpec.imageAssets.filter(t => {
          if (this.creativeType === MEDIA_DATA_AD) {
            return t.locationInAd.includes('images')
          } else if (this.previewType === 'SMALL') {
            return t.locationInAd?.includes('previewImageUrl') || t.locationInAd?.includes('imageUrl') || t.locationInAd?.includes('additionalImages')
          } else {
            return t.locationInAd?.includes('imageUrl') || t.locationInAd?.includes('additionalImages') || t.locationInAd?.includes('previewImageUrl')
          }
        })
        if (images.length > 0) {
          if (images.length > 1) {
            images = images.filter(i => !i.locationInAd?.includes('previewImageUrl'))
          }
          return images
        }
      }
      if (this.adSpec.videoAssets?.length > 0) {
        const thumbnails = this.adSpec.videoAssets

        if (thumbnails.length > 0) {
          return thumbnails
        }
      }
      return []
    },
    previewAsset () {
      if (this.previewAssetList.length > 0) {
        return this.previewAssetList[this.previewIndex]
      }
      return null
    },
    previewImgSrc () {
      const path = this.previewAsset?.path || ''
      if (path === '' || this.imageCheck[path] === false) {
        return this.imagePlaceholder
      }
      this.doImageCheck([path])
      return this.swapFallback(path)
    },
    previewAssetsForDetail () {
      if (this.carouselMedia.length > 0) {
        this.doImageCheck(this.carouselMedia.filter(m => m.path).map(m => m.path))
        return this.carouselMedia.map((media, index) => ({
          ...media,
          title: this.getTextAssetsForCarouselItem(media, 'title') || this.title,
          titleHighlight: (this.highlightMap && this.highlightMap[`sub${index}-title`]) ?? '',
          description: this.getTextAssetsForCarouselItem(media, 'description') || this.description,
          descriptionHighlight: (this.highlightMap && this.highlightMap[`sub${index}-description`]) ?? '',
          url: this.getTextAssetsForCarouselItem(media, 'website_url') || this.targetUrl,
          cta: this.getTextAssetsForCarouselItem(media, 'callToAction') || this.cta,
          ctaHighlight: (this.highlightMap && (this.highlightMap[`sub${index}-callToAction`] || this.highlightMap[`sub${index}-website_url`] || this.highlightMap.website_url)) ?? '',
          translate: 0
        }))
          .map(detail => reactive(detail))
      }

      if (this.previewAsset?.path && Array.isArray(this.previewAsset.path)) { // Don't execute an image check if there is no preview asset
        this.doImageCheck(this.previewAsset.path)
      }
      return [{
        ...this.previewAsset,
        title: this.title,
        titleHighlight: this.highlightMap?.title || '',
        description: this.description,
        descriptionHighlight: this.highlightMap?.description || '',
        url: this.targetUrl,
        cta: this.cta,
        ctaHighlight: this.highlightMap?.callToAction || this.highlightMap?.website_url || ''
      }]
    },
    carouselMedia () {
      if (this.creativeType === 'CAROUSEL_AD') {
        const imageAssets = (this.adSpec.imageAssets || [])
          .filter(asset => ['carouselGeoCard', 'imageUrl'].indexOf(asset.locationInAd) > -1)
          .map(asset => ({
            ...asset,
            type: asset.locationInAd === 'carouselGeoCard' ? 'map' : 'image'
          }))
        this.doImageCheck(imageAssets.map(asset => asset.path))
        const videoAssets = (this.adSpec.videoAssets || [])
          .filter(asset => asset.locationInAd === 'videoUrl')
          .map(asset => ({
            ...asset,
            type: 'video'
          }))
        const sorted = _.sortBy(imageAssets.concat(videoAssets), ['order'])
        if (this.showEndCard) {
          sorted.push({
            type: 'endCard',
            order: 999
          })
        }
        return sorted
      }
      return []
    },
    carouselEndCardSeeMoreUrl () {
      const seeMoreUrls = this.adSpec?.textAssets?.filter(t => t.locationInAd.includes('seeMoreUrl')) ?? []
      const priorityUrl = this.getPriorityTextAssetText(seeMoreUrls)
      if (priorityUrl) {
        return !priorityUrl.includes('http') ? 'https://' + priorityUrl : priorityUrl
      }
      return this.hrefToAccountPlanPrimaryDomain
    },
    slideshowAdMedia () {
      if (this.creativeType === 'SLIDESHOW_AD') {
        return this.registerSlideshow(this.adSpec)
      }
      return {}
    },
    body () {
      const bodies = this.adSpec?.textAssets?.filter(t =>
        t.locationInAd.includes('bodies') || t.locationInAd.includes('body')) ?? []

      const body = this.getPriorityTextAssetText(bodies) || ''
      _.delay(() => { // if the text does not overflow, set the see more link to false
        const el = this.$refs.bodyContainer
        if (el) {
          if (el.offsetHeight + 2 >= el.scrollHeight) {
            this.showSeeMoreBodyLink = false
          }
        }
      }, 100)
      return body
    },
    displayUrl () {
      const displayUrls = this.adSpec?.textAssets?.filter(t =>
        t.locationInAd.includes('display_url')) ?? []

      return this.getPriorityTextAssetText(displayUrls) || this.accountPlan?.primaryDomain?.replace('www.', '') || ''
    },
    targetUrl () {
      if (this.creativePlan?.destinationUrl?.length > 0) {
        const result = /^(https?:\/\/)?(.*)/i.exec(this.creativePlan.destinationUrl)
        if (result?.length > 2 && this.accountPlan?.primaryDomain !== result[2]) {
          return this.creativePlan.destinationUrl
        }
      }
      const websiteUrls = this.adSpec?.textAssets?.filter(t =>
        t.locationInAd.includes('website_url')) ?? []
      return this.getPriorityTextAssetText(websiteUrls) || ''
    },
    hrefToAccountPlanPrimaryDomain () {
      const domain = this.accountPlan?.primaryDomain
      if (domain) {
        return !domain.includes('http') ? 'https://' + domain : domain
      }
      return '#'
    },
    title () {
      const titles = this.adSpec?.textAssets?.filter(t =>
        this.creativeType === MEDIA_DATA_AD
          ? t.locationInAd.includes('titles')
          : t.locationInAd.includes('title')) ?? []

      return this.getPriorityTextAssetText(titles) || ''
    },
    description () {
      const descs = this.adSpec?.textAssets?.filter(t =>
        this.creativeType === MEDIA_DATA_AD
          ? t.locationInAd.includes('descriptions')
          : t.locationInAd.includes('description')) ?? []

      return this.getPriorityTextAssetText(descs) || ''
    },
    cta () {
      const ctas = this.adSpec?.textAssets?.filter(t =>
        this.creativeType === MEDIA_DATA_AD
          ? t.locationInAd.includes('call_to_action_types')
          : t.locationInAd.includes('callToAction')) ?? []

      return this.getPriorityTextAssetText(ctas) || ''
    },
    showEndCard () {
      const endCardAsset = this.adSpec?.textAssets?.find(t => t.locationInAd.includes('enablePageEndCard'))
      if (endCardAsset) {
        return (endCardAsset.text || '').toLowerCase() === 'true'
      }
      return false
    },
    footerLabel () {
      const footerLabel = this.adSpec?.textAssets?.find(t => t.locationInAd.includes('footerLabel'))
      return footerLabel?.text ?? 'Write something...'
    },
    overlayTemplateObject () {
      const overlayTemplate = this.adSpec?.textAssets?.find(t => t.locationInAd.includes('overlayTemplate'))
      if (!overlayTemplate || overlayTemplate.text === 'noPriceOverlay') {
        return { enabled: false }
      }
      const shape = overlayTemplate.text.split('_')[0]
      if (shape === 'triangle') {
        // disabling the triangle overlay for now.
        // TODO the triangle overlay
        return { enabled: false }
      }

      const textFont = this.adSpec?.textAssets?.find(t => t.locationInAd.includes('overlayTextFont'))?.text
      let defaultFontFamily = 'sans-serif'
      let fontFamily = 'Lato'
      let fontWeight = '600'
      let fontSize = '120%'
      if (textFont?.includes('serif')) defaultFontFamily = 'serif'
      if (textFont?.includes('open')) fontFamily = 'Arial'
      if (textFont?.includes('pt_serif')) fontFamily = 'PT Serif'
      if (textFont?.includes('roboto')) fontFamily = 'Roboto, Helvetica'
      if (textFont?.includes('droid_serif')) fontFamily = 'Didot'
      if (textFont?.includes('nunito')) fontFamily = 'Nunito, Gill Sans'
      if (textFont?.includes('noto')) fontFamily = 'Noto Sans'
      if (textFont?.includes('regular')) fontWeight = '400'
      if (textFont?.includes('medium')) fontWeight = '500'
      if (textFont?.includes('condensed')) fontSize = '100%'

      const textType = this.adSpec?.textAssets?.find(t => t.locationInAd.includes('overlayTextType'))?.text
      return {
        enabled: true,
        template: overlayTemplate.text,
        shape,
        textType,
        position: this.adSpec?.textAssets?.find(t => t.locationInAd.includes('overlayPosition'))?.text?.replaceAll('_', '-') ?? 'top-left',
        themeColor: this.adSpec?.textAssets?.find(t => t.locationInAd.includes('overlayThemeColor'))?.text,
        themeFontColor: this.adSpec?.textAssets?.find(t => t.locationInAd.includes('overlayThemeColor'))?.text?.split('_')[3] ?? 'ffffff',
        themeBackgroundColor: shape === 'triangle' ? 'transparent' : this.adSpec?.textAssets?.find(t => t.locationInAd.includes('overlayThemeColor'))?.text?.split('_')[1] ?? 'e50900',
        opacity: (this.adSpec?.textAssets?.find(t => t.locationInAd.includes('overlayOpacity'))?.text ?? 100) / 100,
        fontFamily: `${fontFamily}, ${defaultFontFamily}`,
        fontWeight,
        fontSize

      }
    },
    frameImageObject(){
      const frameOverlay = this.creativePlan.adSpec.imageAssets?.find(t => t.locationInAd.includes('overlayFrameImageUrl'))
      if(!frameOverlay){
        return { enabled: false }
      }
      return{
        enabled: true,
        src: frameOverlay.path,
        position: frameOverlay.textAssets?.find(t => t.locationInAd.includes('overlayFramePosition'))?.text?.replaceAll('_', '-') ?? 'top-left',
        scale: frameOverlay.textAssets?.find(t=> t.locationInAd.includes('overlayScale'))?.text ?? '100',
        blendingMode: frameOverlay.textAssets?.find(t=> t.locationInAd.includes('overlayBlendingMode'))?.text ?? 'normal',
        opacity: parseInt(frameOverlay.textAssets?.find(t=> t.locationInAd.includes('overlayFrameOpacity'))?.text ?? '100') / 100
      }
    },
    hasPinnedAsset () {
      const pinned = this.adSpec?.textAssets
        ?.concat(this.adSpec?.imageAssets?.flatMap(i => i.textAssets || []))
        ?.concat(this.adSpec?.videoAssets?.flatMap(v => v.textAssets || []))
        ?.filter(textAsset => textAsset.pinned)
      return pinned?.length > 0 || false
    }
  },
  methods: {
    filterizeCta (value) {
      if (!value) return ''
      value = value.toString()
      if (value === 'BOOK_TRAVEL') {
        value = 'BOOK_NOW'
      }
      return value
    },
    overlayLengthLimit (value, shape) {
      if (!value) return ''
      const limit = priceOverlayTextLengthLimits[shape]
      if (value.length > limit) {
        return ''
      }
      return value
    },
    isImage,
    isVideo,
    async loadSamplePreview (creativePlan = {}) {
      const { creativePlanId, previewAdSpec } = creativePlan
      if ((!this.creativePlan.creativePlanId && !creativePlanId) || this.creativePlan.creativePlanId === creativePlanId) {
        if (previewAdSpec) {
          this.$setCompat(this, 'localPreview', previewAdSpec)
          this.imageCheckAll()
        } else {
          this.loadDynamicPreview()
        }
      }
    },
    async loadDynamicPreview () {
      const validCreativeTypes = ['DYNAMIC_PRODUCT_AD', 'PROMOTED_POST', 'COLLECTION_AD']
      if (this.previewType === 'SAMPLE' && validCreativeTypes.includes(this.creativePlan?.creativeType) && this.user?.userRegistryId) {
        if (this.creativePlan.adGroupPlanId || this.creativePlan.adSpec?.textAssets?.findIndex(t => t.locationInAd === 'productScopePlanId') > -1) {
          if (this.$authn.isAuthenticated()) {
            const dynamicPreview = await this.$res.fetch.dynamicAdsCreativePreview(this.creativePlan)
            if (dynamicPreview?.previewAdSpec) {
              this.$setCompat(this, 'localPreview', dynamicPreview.previewAdSpec)
              this.imageCheckAll()
            }
          }
        }
      }
    },
    getTextAssetsForCarouselItem (item, location) {
      const topLevelLocationAssets = this.adSpec?.textAssets?.filter(asset => asset.locationInAd === location) ?? []
      const pinnedLocationAssets = topLevelLocationAssets.filter(asset => asset.pinned)
      if (pinnedLocationAssets.length > 0) {
        return this.getPriorityTextAssetText(pinnedLocationAssets)
      }

      const itemAssets = item?.textAssets?.filter(ta => ta.locationInAd === location) ?? []

      return this.getPriorityTextAssetText(itemAssets) || this.getPriorityTextAssetText(topLevelLocationAssets)
    },

    getPriorityTextAssetText (assets = []) {
      if (assets.length <= 0) {
        return ''
      }
      let choices = assets.filter(asset => asset.pinned)
      if (choices.length === 0) {
        choices = assets
      }
      return choices[_.random(choices.length - 1)].text
    },
    setPreviewIndex (toIndex) {
      const listSize = this.previewAssetList.length - (this.showEndCard ? 0 : 1)
      const normalizedIndex = Math.max(0, Math.min(toIndex, listSize))
      // all the same delta between values (242), except between 0 and 1.
      const offsets = [0, -213, -455, -697, -939, -1181, -1423, -1665, -1907, -2149, -2391]
      this.previewIndex = normalizedIndex
      if (this.creativeType === 'CAROUSEL_AD') {
        const ref = this.$refs.carouselContainer
        if (ref) {
          let offset = offsets[normalizedIndex]
          if (normalizedIndex === listSize) {
            offset += 29
          }
          ref.style.transform = `translateX(${offset}px)`
        }
      }
    },
    imageCheckAll () {
      this.doImageCheck(this.adSpec.imageAssets
        .filter(ia => ia.path?.length > 0)
        .map(ia => ia.path))
    },
    async doImageCheck (imageUrls = []) {
      const imageCheck = async (url) => {
        this.$setCompat(this.imageCheck, url, 'PENDING')
        try {
          const resUrl = await doesImageExist(url)
          this.$setCompat(this.imageCheck, resUrl, true)
        } catch (errUrl) {
          this.$setCompat(this.imageCheck, errUrl, false)
        }
      }

      await Promise.all(
        imageUrls?.filter(url => url && !this.imageCheck.hasOwnProperty(url)).map(imageCheck) ?? []
      )
      return imageUrls?.filter(url => this.imageCheck[url] === true)
    },
    swapFallback (url) {
      return url === 'fallback' || url === '' || this.imageCheck[url] === false
        ? this.imagePlaceholder
        : url
    },
    registerSlideshow (adspec) {
      const imageUrls = (this.adSpec.imageAssets || []).filter(asset => asset.locationInAd === 'imageUrl')
        .concat((this.adSpec.imageAssets || []).filter(asset => asset.locationInAd === 'additionalImages'))
        .slice(0, 7)
        .map(asset => asset.path)
      const imageUrlDiffArray = Array.zip(imageUrls, this.slideshow.imageUrls, (newUrl, existingUrl) => newUrl === existingUrl ? existingUrl : null)
        .filter(url => !!url)
      const frameDuration = parseFloat(this.getPriorityTextAssetText(
        (this.adSpec.textAssets || []).filter(asset => asset.locationInAd === 'frameDuration')) || '1.75') * 1000
      const transitionDuration = parseFloat(this.getPriorityTextAssetText(
        (this.adSpec.textAssets || []).filter(asset => asset.locationInAd === 'transitionDuration')) || '.25') * 1000
      const isSame = imageUrls.length === this.slideshow.imageUrls.length &&
        imageUrlDiffArray.length === this.slideshow.imageUrls.length &&
        frameDuration === this.slideshow.frameDuration &&
        transitionDuration === this.slideshow.transitionDuration

      if (!isSame) {
        this.clearSlideshowInterval()

        this.slideshow.currentInterval = setInterval(() => {
          const prevIndex = this.slideshow.currentIndex
          this.slideshow.currentIndex = prevIndex < this.slideshow.imageUrls.length - 1 ? prevIndex + 1 : 0
        }, frameDuration)

        this.$setCompat(this, 'slideshow', {
          imageUrls,
          frameDuration, // used in setInterval above
          transitionDuration, // used in :style attribute on slideshow <img>
          currentIndex: this.slideshow.currentIndex,
          currentInterval: this.slideshow.currentInterval
        })
      }
      return this.slideshow
    },
    clearSlideshowInterval () {
      if (this.slideshow.currentInterval) {
        clearInterval(this.slideshow.currentInterval)
      }
    },
    shouldShowPreviewOverlay (carouselItem, index) {
      if (!carouselItem) return false
      return this.creativePlan.creativeType === 'DYNAMIC_PRODUCT_AD' && index === this.previewIndex && !carouselItem.usage?.includes('static')
    }
  }
}
</script>

<style lang='scss' scoped>


  .collection-ad-wrapper {
    &:not(.report-mode) & {
      height: 665.66px;
    }
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .collection-ad-container {
      &:not(.report-mode) & {
        height: 625.66px;
      }
      width: 430px;
      margin: 12px;
      background: white;
      cursor: pointer;

      display: flex;
      flex-direction: column;
      position: relative;

      .preview-elements {
        &:not(.report-mode) & {
          height: 625.66px;
        }
        overflow: auto;

        .preview-media-container {
          height: 430px;
          width: 430px;
          background: gainsboro;
          position: relative;

          .preview-media {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .preview-products-container {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          margin-top: .5rem;

          .preview-product {
            width: 49.5%;
            a {
              position: relative;
              display: inline-block;

              img {
                width: 100%;
                height: 180px;
                object-fit: cover;
              }

            }

            .product-text {
              margin: 10px 20px 16px 12px;

              .product-headline {
                line-height: 14px;
                max-height: 28px;
                -webkit-line-clamp: 2;

                color: $dark;
                font-size: 12px;
                font-weight: normal;
                overflow: hidden;
                text-align: left;
                text-overflow: ellipsis;
                word-wrap: break-word;
              }

              .product-description {
                line-height: 16px;
                height: 32px;
                -webkit-line-clamp: 2;

                font-size: 12px;
                font-weight: bold;
                margin-top: 4px;
                overflow: hidden;
                text-align: left;
              }
            }
          }
        }

        .see-more-container {
          color: $dark;
          background: white;
          text-align: center;

          .see-more-text {
            font-size: 12px;
            line-height: 24px;
            text-align: center;
          }
        }

        .headline-wrapper {
          display: flex;
          align-items: center;
          position: relative;
          padding: 10px;
          background-color: #f5f6f7;
          margin-bottom: 3px;
          min-height: 14px;

          .headline-container {
            flex: 1 1 auto;
            order: 0;
            overflow: hidden;
            word-wrap: break-word;

            .headline {
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              display: -webkit-box;
              float: none;
              overflow: hidden;
              white-space: normal;
              font-size: .875rem;
              color: #000;
              font-weight: bold;
            }
          }
        }

        .preview-product-thumbnails-wrapper {
          display: flex;
          justify-content: space-around;
          margin: 1rem 0;
          text-align: center;

          .preview-product-thumbnail-container {
            width: 32%;

            &.preview-product-thumbnail-overlay-container {
              position: relative;

              .preview-product-thumbnail-overlay {
                position: absolute;
                color: white;
                font-weight: bold;
                font-size: 14px;
                background-color: rgba(108, 117, 125, 0.65);
                height: 100%;
                width: 100%;
                z-index: 2;
                display: flex;
                justify-content: center;
                align-items: center;
              }
            }

            .preview-product-thumbnail {
              object-fit: cover;
              width: 100%;
            }
          }
        }
      }

      .footer-wrapper {
        bottom: 0;
        overflow: hidden;
        padding: 8px 0;
        width: 100%;
        z-index: 2;

        .footer-container {
          padding: 8px 0px;
          background: white;
          font-size: 14px;
          line-height: 18px;

          a.footer-button {
            display: block;
            border: 2px solid black;
            border-radius: 8px;
            margin-left: auto;
            margin-right: auto;
            width: 90%;
            color: black;

            .footer-text {
              padding: 12px;
              text-align: center;
            }
          }
        }
      }
    }
  }

  header {
    display: flex;
    align-items: flex-start;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    margin-bottom: 12px;

    .logo {
      padding-right: 8px;
      display: flex;
      vertical-align: top;

      img {
        border-radius: 50%;
        vertical-align: bottom;
        width: 40px;
        height: 40px;
        border: 0;
        object-fit: cover;
      }
    }
    .name-wrapper {
      display: flex;
      align-self: center;

      .name-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-self: center;
        min-width: 220px;

        .name {
          line-height: 17px;
          color: #1d2129;
          font-size: .875rem;
          font-weight: 700;
        }
        .sponsored {
          font-size: .75rem;
          color: #90949c;
          position: relative;
          margin-top: -0.3333em;
          top: .3333em;
        }
      }
    }
  }

  .body-wrapper {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    word-wrap: break-word;
    line-height: 18px;
    font-size: .875rem;
    margin: 8px 0;
    padding: 0 10px;

    .body-container {
      white-space: pre-line;
      //min-height: 54px; // TODO you might not need this. see how it looks when you add the main media

      &.see-more {
        overflow: hidden;
        white-space: pre-line;
        zoom: 1;
        height: 54px;
      }

      &.see-more:before {
        content: '';
        float: left;
        height: 100%;
        position: relative;
        width: 5px;
      }

      &.see-more:after {
        clear: both;
        content: '.';
        display: block;
        font-size: 0;
        height: 0;
        line-height: 0;
        visibility: hidden;
      }

      .body-spacer {
        float: right;
        height: calc(100% - 18px);
        width: 0;
      }
      .body-see-more {
        clear: right;
        float: right;
        margin: 0 5px;
        .see-more-link {
          font-size: .875rem !important;
          line-height: 1;
        }
      }
      .body-text {
        display: block;
        float: none;
        width: 100%;
      }
    }
  }

  .standard-ad-wrapper {
    color: black;
    font-size: 12px;
    line-height: 15.36px;
    text-align: left;

    .standard-ad-container {
      background-color: white;
      overflow: auto;
      margin: 0 auto;
      width: 320px;

      .media-footer-wrapper {
        position: relative;
        z-index: 0;
        width: 320px;
        margin-bottom: 8px;

        .carousel-wrapper {
          padding: 5px 10px;
          margin: 8px 0;

          .carousel-container {
            position: relative;
            z-index: 0;
            max-width: 100%;
            padding: 1px 0;
            margin: -1px 0;

            .scrollable-carousel-frame {
              overflow: hidden;
              position: relative;
              display: flex;
              z-index: 0;

              .scrollable-carousel-window {
                position: relative;
                display: flex;
                flex-direction: column;
                flex-grow: 1;

                .scrollable-carousel-list {
                  display: flex;
                  flex-wrap: nowrap;
                  position: relative;
                  list-style: none;
                  margin: 0;
                  padding: 0;
                  transition: transform .2s ease-in-out;

                  .carousel-card {
                    border: 1px solid #ccc;
                    width: 230px;
                    border-radius: 4px;
                    overflow: hidden;
                    margin-right: 12px;

                    .carousel-card-media-container {
                      position: relative;
                      height: 230px;
                      width: 230px;
                      display: flex;
                      align-items: center;
                      justify-content: center;

                      .carousel-card-media {
                        display: block;
                        object-fit: cover;
                        border: 0;
                        box-sizing: border-box;
                        border-radius: 4px;
                      }
                    }

                    .carousel-card-footer-container {
                      border-radius: 0 0 4px 4px;
                      padding: 8px;
                      display: flex;
                      justify-content: space-between;
                      background-color: rgb(240, 242, 245);

                      .carousel-card-footer-label {
                        word-break: break-word;
                        max-height: 54px;
                        overflow: hidden;
                        line-height: 20px;
                        display: flex;
                        flex-direction: column;
                        flex: 1 1 auto;
                        font-size: 1rem;
                        min-width: 0;

                        .carousel-card-footer-headline-container {
                          display: flex;
                          color: #000;
                          font-size: .875rem;
                          font-weight: bold;
                          white-space: normal;

                          .carousel-card-footer-headline {
                            line-height: 18px;
                            -webkit-line-clamp: 2;
                            -webkit-box-orient: vertical;
                            display: -webkit-box;
                            float: none;
                            overflow: hidden;
                            white-space: normal;
                            width: 100%;
                          }
                        }

                        .carousel-card-description {
                          font-weight: normal;
                          min-height: 32px;
                          overflow: hidden;
                          margin-top: 3px;
                          line-height: 16px;
                          text-overflow: ellipsis;
                          font-size: .75rem;
                          color: #4b4f56;
                          white-space: nowrap;
                        }
                      }

                      .carousel-card-cta-button-container {
                        padding-left: 10px;
                        display: flex;
                        justify-content: center;
                        flex-direction: column;

                        .carousel-card-cta-button {
                          display: flex;
                          align-items: center;
                          border-radius: 4px;
                          text-transform: uppercase;
                          white-space: nowrap;
                          padding: 3px 8px;
                          border: 1px solid rgb(68, 73, 80);

                          .carousel-card-cta-text {
                            font-size: 12px;
                            line-height: 16px;
                            letter-spacing: normal;
                            overflow-wrap: normal;
                            text-align: left;
                            color: rgb(68, 73, 80);
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                          }
                        }
                      }
                    }
                  }

                  .carousel-card-scrim-container {
                    z-index: 1;
                    position: absolute;
                    right: 0;
                    left: 0;
                    top: 0;
                    transition: .2s opacity ease-in-out;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    opacity: 0;

                    &:hover {
                      opacity: 1;
                    }

                    .carousel-card-advancer {
                      padding: 0;
                      cursor: pointer;
                      position: relative;
                      margin: 0;
                      width: 100%;
                      background-color: transparent;
                      border: 0;
                      outline: none;

                      .carousel-card-scrim {
                        width: 230px;
                        height: 230px;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        position: absolute;
                        background-color: #000;
                        border-radius: 4px;
                        overflow: hidden;
                        opacity: .4;
                      }
                    }
                  }
                }
              }
            }
          }
        }

        .media-footer-border {
          position: absolute;
          left: 0;
          z-index: 1;
          width: 320px;
          top: 0;
          border-top: 1px solid rgba(0,0,0,.08);
        }

        .preview-media {
          max-width: 100%;
          object-fit: cover;
          border: 0;
        }

        .footer-container {
          display: flex;
          align-items: center;
          min-height: 36px;
          padding: 12px;
          background-color: #e8eaee;

          .link-title-container {
            flex: 1 1 auto;
            order: 0;
            align-self: auto;
            max-height: 54px;
            overflow: hidden;
            word-wrap: break-word;

            .display-link-container {
              line-height: 16px;
              width: 100%;

              .display-link {
                display: block;
                max-width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                font-size: .75rem;
                text-transform: uppercase;
                color: rgb(70, 73, 80);
              }
             }

            .title-container {
              color: #1c2028;
              display: flex;
              flex-direction: column;

              .title {
                line-height: 18px;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                display: -webkit-box;
                float: none;
                overflow: hidden;
                white-space: normal;
                width: 100%;
                font-size: .875rem;
                font-weight: bold;
              }
            }

            .description-container {
              color: rgb(70, 73, 80);

              .description {
                display: block;
                max-width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                line-height: 16px;
                font-size: .75rem;
              }
            }
          }

          .cta-button-container {
            margin-left: 12px;

            .cta-button {
              display: flex;
              align-items: center;
              padding: 3px 8px;
              border-radius: 4px;
              text-overflow: ellipsis;
              text-transform: uppercase;
              white-space: nowrap;
              border: solid 1px rgb(68, 73, 80);

              .cta-text {
                font-size: 12px;
                line-height: 16px;
                letter-spacing: normal;
                overflow-wrap: normal;
                text-align: left;
                color: rgb(68, 73, 80);
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
            }
          }
        }
      }
    }
  }

  .page-name {
    font-size: 14px;
    line-height: 18px;
  }

  .sponsored {
    font-size: 12px;
    line-height: 16px;
    color: $fluency-lightgray;
  }

  .preview-switch-text {
    font-size: 12px;
  }

.img-square-thumbnail {
  min-width: 58px;
  min-height: 58px;
  object-fit: cover;
}

/*The idea is to apply the content property to the broken image.
Normally :after or :before do not apply to images, but when they're broken, they're applied.*/
.fallback:before {
  content: ' ';
  display: block;
  position: absolute;
  width: 47px;
  height: 47px;
  background-color: white;
  background-image: url(../../assets/img/image-placeholder-47x47.png);
  //background-image: url(../../../apps/backpack/src/assets/img/image-placeholder-47x47.png);
  background-size: cover;
}

  .overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    background-color: transparent;
    opacity: .8;
    overflow: auto;
  }

  .video-indicator {
    position: absolute;
    top: 8px;
    left: 12px;
    font-size: 28px;
    color: white;
    text-align: center;

    &.video-indicator-lg {
      font-size: 150px;
      top: 48px;
      left: 96px;
    }
  }

  .preview-image-indicator {
    position: absolute;
    top: 50%;
    font-size: 32px;
    color: white;
    width: 100%;
    text-align: center;
    transform: rotate(-38deg);
  }

  .frame-image-container {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 100%;

    &.top-left {
      justify-content: start;
      align-items: start;
    }

    &.top-right {
      justify-content: end;
      align-items: start;
    }

    &.bottom-left {
      justify-content: start;
      align-items: end;
    }

    &.bottom-right {
      justify-content: end;
      align-items: end;
    }

    &.center {
      justify-content: center;
      align-items: center;
    }

    &.top {
      justify-content: center;
      align-items: start;
    }

    &.bottom {
      justify-content: center;
      align-items: end;
    }

    &.right {
      justify-content: end;
      align-items: center;
    }

    &.left {
      justify-content: start;
      align-items: center;
    }

    &.blend-lighten {
      mix-blend-mode: lighten;
    }

    &.blend-multiply {
      mix-blend-mode: multiply;
    }
  }

  .overlay-template-container {
    position: absolute;

    &.top-left {
      top: 1rem;
      left: 1rem;
    }
    &.top-left.triangle-container {
      top: 0;
      left: 0;
    }
    &.top-right {
      top: 1rem;
      right: 1rem;
    }
    &.top-right.triangle-container {
      top: 0;
      right: 0;
    }
    &.bottom-left {
      bottom: 1rem;
      left: 1rem;
    }
    &.bottom-left.triangle-container {
      bottom: 0;
      left: 0;
    }
    &.bottom-right {
      bottom: 1rem;
      right: 1rem;
    }
    &.bottom-right.triangle-container {
      bottom: 0;
      right: 0;
    }

    .overlay-shape {
      min-height: 1rem;
    }

    .rectangle {
      padding: .1rem .2rem;
    }

    .pill {
      border-radius: .5rem;
      padding: .1rem .5rem;
    }
    .circle {
      height: 3rem;
      width: 3rem;
      border-radius: 3rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      overflow:hidden;
    }
    .triangle {
      width: 0;
      height: 0;
      &.top-left {
        border-top: 4rem solid red;
        border-right: 4rem solid transparent;
      }
      &.top-right {
        border-top: 4rem solid red;
        border-left: 4rem solid transparent;
      }
      &.bottom-left {
        border-bottom: 4rem solid red;
        border-right: 4rem solid transparent;
      }
      &.bottom-right {
        border-bottom: 4rem solid red;
        border-left: 4rem solid transparent;
      }
    }
  }

.btn-xs, .btn-group-xs > .btn {
  padding: .18rem .3rem;
  font-size: .425rem;
  line-height: 1.2;
  border-radius: .15rem;
}

</style>
