<template>
  <div class="position-relative" :class="{'overflow-auto': !smaller, 'overflow-hidden': smaller}" :style="smallerStyle.container">
    <iframe
      v-if="show"
      :src="adLocation ? 'https://ads.fluencyinc.co/' + adLocation : undefined"
      :srcdoc="tag"
      :width="width"
      :height="height"
      class="border-0"
      :style="smallerStyle.iframe + 'box-shadow: 0 0 1px #000000'">
    </iframe>
  </div>
</template>

<script>
export default {
  name: 'adPreviewDspHtml',
  props: ['creativePlan', 'smaller'],
  data () {
    return {
      maxLeg: 200,
      show: false
    }
  },
  computed: {
    width () {
      return this.creativePlan?.adSpec?.textAssets?.find(as => as.locationInAd === 'ad_dim_width')?.text
    },
    height () {
      return this.creativePlan?.adSpec?.textAssets?.find(as => as.locationInAd === 'ad_dim_height')?.text
    },
    adLocation () {
      return this.creativePlan?.adSpec?.textAssets?.find(as => as.locationInAd === 'ad_location')?.text
    },
    tag () {
      const tagKeys = ['HTML Tag', 'body']
      return this.creativePlan?.adSpec?.textAssets?.find(as => tagKeys.includes(as.locationInAd))?.text
    },
    smallerStyle () {
      const style = {
        iframe: '',
        container: ''
      }
      if (this.smaller && this.height && this.width) {
        const height = parseInt(this.height)
        const width = parseInt(this.width)

        const vertical = width > height
        const adjustment = vertical ? this.maxLeg / width : this.maxLeg / height
        style.iframe = `transform: translate(-50%, -50%) scale(${adjustment}, ${adjustment});position: absolute; left: 50%; top: 50%;`
        style.container = height > 200 ? `height: ${this.maxLeg}px` : `height: ${this.height}px`
      }
      return style
    }
  },
  mounted () {
    window.setTimeout(() => { this.show = true }, 1000)
  }
}
</script>
