<template>
  <div>
    <b-card
      tag="article"
      class="special-card"
    >
      <div>
        <div v-if="!smaller" class="iframe-container">
          <iframe v-if="getVideoUrl('youTubeVideos')"
                  :src="getVideoUrl('youTubeVideos')"
                  allowfullscreen=""
                  frameborder="0"
                  class="videoIframe"
                  width="358"
                  height="200"></iframe>
        </div>

        <div v-else-if="getThumbnail('youTubeVideos')" class="pb-2">
          <img :src="getThumbnail('youTubeVideos')"
               class="img-fluid"
               alt="thumbnail of youtube video"
               loading="lazy"/>
        </div>
      </div>
      <div class="d-flex">
        <div>
          <div class="logo" :style="{ 'background-image': `url(${getImageAsset('logoImages')})` }"></div>
        </div>
        <div class="pl-3">
          <h4>{{getRandomAsset('headlines')}}</h4>
          <span class="muted pb-3"></span>{{getRandomAsset('descriptions')}}

          <div class="mt-2">
            <span class="ad">Ad</span>
            {{ getAsset('businessName')[0] }}
          </div>
        </div>
      </div>

    </b-card>

  </div>
</template>

<script>
import { getImageSourceFromYouTubeVideo } from 'core-ui/utils/youtube'

export default {
  name: 'adPreviewDiscoveryVideo',
  props: {
    creativePlan: {
      type: Object,
      default: () => { return {} }
    },
    smaller: {
      type: Boolean,
      default: false
    }
  },
  computed: {
  },
  methods: {
    youTubeUrlFormatter (url) {
      const protocol = url.startsWith('http://') ? 'http://' : 'https://'
      const domain = 'www.youtube.com'
      let id = url.trim()
      const lastChar = id.substring(id.length - 1, id.length)

      // 1) REMOVE TRAILING `/`
      if (lastChar === '/') {
        id = id.substring(0, id.length - 1)
      }

      // 2) REMOVE URL & QUERY STRING SYNTAX
      id = id.replace('/watch?v=', '/')

      // 3) REMOVE EVERYTHING BEFORE FINAL `/`
      id = id.substring(id.lastIndexOf('/') + 1)

      return `${protocol}${domain}/embed/${id}`
    },
    getAsset (assetKey) {
      let matchingAssets = []
      // return Array.from(this.creativePlan.adSpec.textAssets)
      Object.keys(this.creativePlan.adSpec).forEach(assetType => {
        // console.log(this.creativePlan.adSpec[assetType])
        this.creativePlan.adSpec[assetType].forEach(asset => {
          if (asset.locationInAd === assetKey) {
            matchingAssets.push(asset.text || asset.path)
          }
        })
      })
      // handle case where ad spec is singular instead of plural i.e. headline vs headlines
      if (assetKey.endsWith('s') && matchingAssets.length === 0) {
        matchingAssets = this.getAsset(assetKey.substring(0, assetKey.length - 1))
      }
      return matchingAssets
    },
    getRandomAsset (assetKey) {
      const matchingAssets = this.getAsset(assetKey)
      const max = matchingAssets.length - 1
      const min = 0
      const randomIndex = Math.floor(Math.random() * (max - min + 1)) + min
      return matchingAssets[randomIndex]
    },
    getImageAsset (imageKey) {
      return this.creativePlan.adSpec.imageAssets.find(asset => asset.locationInAd === imageKey)?.path
    },
    getVideoAsset (videoKey) {
      return this.creativePlan.adSpec.videoAssets.find(asset => asset.locationInAd === videoKey)?.path
    },
    getVideoUrl (videoKey) {
      const path = this.getVideoAsset(videoKey)

      if (!path) return ''
      const url = this.youTubeUrlFormatter(path)
      return `${url}?modestbranding=1`
    },
    getThumbnail (videoKey) {
      const path = this.getVideoAsset(videoKey)

      console.log(path)
      if (!path) return ''

      return getImageSourceFromYouTubeVideo(path)
    }
  }
}
</script>

<style lang="scss" scoped>
.special-card {
  box-shadow: 3px 3px 5px 2px #ccc;
  &.card {
    border-top: none;
  }

  .logo {
    height: 40px;
    width: 40px;
    border-radius: 40px;
    background-color: $light;
    background-size: contain;
    background-position: center center;
  }

  .iframe-container {
    width: 358px;
  }

  .ad {
    font-size: 80%;
    background-color: #FCC02E;
    border-radius: 3px;
    color: #fff;
    font-weight: bold;
    font-size: 11px;
    padding: 3px 5px;
    line-height: 11px;
    vertical-align: baseline;
  }
}

</style>
