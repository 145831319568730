<template>
  <div>
    <ad-preview-discovery :creative-plan="creativePlan" :show-image="!smaller"></ad-preview-discovery>
    <!--
    <b-tabs>
      <b-tab title="Search">
        <ad-preview-search :creative-plan="creativePlan" :extension-options="extensionOptions" :smaller="smaller"/>
      </b-tab>
      <b-tab title="Discover">
        <ad-preview-discovery :creative-plan="creativePlan" :show-image="!smaller"></ad-preview-discovery>
      </b-tab>
    </b-tabs>
    -->
  </div>
</template>

<script>
import AdPreviewDiscovery from './adPreviewDiscovery'

export default {
  name: 'adPreviewPerformanceMax',
  components: { AdPreviewDiscovery },
  props: ['creativePlan', 'smaller', 'extensionOptions']
}
</script>

<style scoped>

</style>
