<template>
  <div class="row">
    <div :class="reportMode ? 'col-12' : 'col-6'">
      <p v-if="showPreviewMessage">HTML image ads are not editable. This is essentially just a full-size preview.</p>
      <template v-if="fallbackImg && !forceShowIFrame">
        <p v-if="showLabel" class="text-muted mt-5 mb-2">Static Preview:</p>
        <img :src="fallbackImg.path" :width="imgMaxHeight ? 'auto' : fallbackImg.width" :height="fallbackImg.height" class="img-fluid" :style="imgMaxHeight ? `max-height: ${imgMaxHeight}px` : undefined" />
      </template>

      <iframe-display-ad-loader v-else
                                :src="`${adSpec.htmlAdAssets[0].adAssetFile.filePath}?payload=${urlPayload}`"
                                :text-assets="{ framePostBody: adSpec.textAssets }"
                                :width="adSpec.htmlAdAssets[0].width"
                                :height="adSpec.htmlAdAssets[0].height"></iframe-display-ad-loader>
    </div>
    <div v-if="!reportMode" class="col-6">
      <b-form-group v-if="value.syntheticDestinationUrl" label="Example Destination URL" description="This URL is an example only. The vehicle in the URL may not be the one served while advertising and URL Tracking Params won't be in the example.">
        <b-input-group>
          <b-form-input :value="value.syntheticDestinationUrl" disabled></b-form-input>
          <b-input-group-append>
            <a :href="value.syntheticDestinationUrl" target="_blank" class="input-group-text bg-white text-success">
              <fluency-icon type="link"></fluency-icon>
            </a>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
    </div>
  </div>
</template>

<script>
import IframeDisplayAdLoader from './iframeDisplayAdLoader'
export default {
  name: 'HTML5DisplayAds',
  components: { IframeDisplayAdLoader },
  props: {
    value: {
      type: Object,
      required: true
    },
    showLabel: {
      type: Boolean,
      default: true
    },
    forceShowIFrame: {
      type: Boolean,
      default: false
    },
    showPreviewMessage: {
      type: Boolean,
      default: true
    },
    reportMode: {
      type: Boolean,
      default: false
    },
    imgMaxHeight: {
      type: Number
    }
  },
  data () {
    return {}
  },
  computed: {
    adSpec () {
      return this.value.adSpec
    },
    fallbackImg () {
      let returnVal = null

      if (this.adSpec?.imageAssets) {
        this.adSpec.imageAssets.forEach(i => {
          if (i.locationInAd === 'fallback') {
            returnVal = i
          }
        })
      }

      return returnVal
    },
    urlPayload () {
      let returnVal = null

      if (this.adSpec?.textAssets) {
        this.adSpec.textAssets.forEach(i => {
          if (i.locationInAd === 'payload') {
            returnVal = i.text
          }
        })
      }

      return returnVal
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
