<template>
  <div class="d-flex bg-white p-4" style="max-width: 400px; font-family: 'Roboto', sans-serif ">
    <div class="pr-3" :style="showImage && 'max-width: 73%'">
      <div v-if="assetsByLocation.headlines" class="font-weight-bold text-dark" style="font-size:125%;">{{assetsByLocation.headlines[0].text}}</div>
      <div class="d-flex flex-wrap">
        <small class="text-white px-2 my-1" style="border-radius: 12%; background-color: #f8cd60">Ad</small>
        <span class="mx-2">{{'\u2022'}}</span>
        <span class="text-black-50">{{primaryDomain}}</span>
        <span v-if="assetsByLocation.path1" class="text-black-50">/{{assetsByLocation.path1[0].text}}</span>
        <span v-if="assetsByLocation.path2" class="text-black-50">/{{assetsByLocation.path2[0].text}}</span>
      </div>
      <div v-if="assetsByLocation.descriptions" class="text-black-50 mr-2">{{assetsByLocation.descriptions[0].text}}</div>
      <div v-if="assetsByLocation.callToActions" class="p-2 mt-3 text-center rounded-pill" style="color: #1A73E8; border: 1px solid #1A73E8; text-transform: uppercase;">{{assetsByLocation.callToActions[0].text}}</div>
    </div>
    <div v-if="showImage && image" :style="`height:80px; width: 80px; background-image: url(${image}); background-size: cover; border-radius: 12%`"></div>
  </div>
</template>

<script>
export default {
  name: 'AdPreviewLocal',
  props: {
    creativePlan: {
      type: Object,
      default: () => {}
    },
    showImage: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    assetsByLocation () {
      return [...this.creativePlan?.adSpec.textAssets, ...this.creativePlan?.adSpec.imageAssets, ...this.creativePlan?.adSpec.videoAssets].groupBy(x => x.locationInAd)
    },
    primaryDomain () {
      return this.$store.getters.activeItem?.primaryDomain || this.$store.getters.activeItem?.accountPlan?.primaryDomain
    },
    image () {
      if (this.assetsByLocation.marketingImages) {
        return this.assetsByLocation.marketingImages[0].path
      } else if (this.assetsByLocation.logoImages) {
        return this.assetsByLocation.logoImages[0].path
      }
      return ''
    }
  }
}
</script>

<style>
  @import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
</style>
