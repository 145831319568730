<template>
  <div class="d-flex flex-column bg-white" :class="smaller ? 'p-1' : 'p-3'" :style="`${smaller ? 'max-height: 100px; max-width: 100px;' : ''}; border-radius: 10px;`">
    <div :class="smaller ? 'mb-1' : 'mb-3'" style="border-radius: 10px; overflow: hidden;">
      <p-carousel v-if="!smaller && creativeType === 'PINTEREST_CAROUSEL_AD'"
                  style="min-width: 385px;"
                  :value="images"
                  v-model:page="carouselSlidePage">
        <template #item="{ data: image, index }">
          <img :src="image.path" :alt="`Carousel Slide ${index + 1}`" class="rounded img-fluid" />
        </template>
      </p-carousel>
      <img v-else :src="image" class="img-fluid">
    </div>
    <div v-if="businessName" class="d-flex align-items-center">
      <fluency-icon type="arrowThickUp" class="text-muted tilt mr-2"></fluency-icon>
      <small class="text-muted">{{businessName}}</small>
      <fluency-icon type="ellipses" class="text-muted ml-auto"></fluency-icon>
    </div>
    <div class="font-weight-bold" :class="{'small': smaller}">{{title}}</div>
    <div v-if="businessName" class="d-flex align-items-center mt-3">
      <div class="bg-dark text-white rounded-circle d-flex align-items-center justify-content-center" style="height: 30px; width: 30px; text-transform: uppercase;">
        {{businessName[0]}}
      </div>
      <div class="ml-3">
        <div class="small font-weight-bold">Promoted by</div>
        <div class="small text-muted">{{businessName}}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AdPreviewPinterest',
  props: {
    creativePlan: {
      type: Object
    },
    smaller: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      carouselSlidePage: 0
    }
  },
  computed: {
    images () {
      return this.creativePlan?.adSpec?.imageAssets?.filter(a => a.locationInAd === 'pinImage')
    },
    image () {
      return this.images[0]?.path
    },
    title () {
      return this.creativePlan?.adSpec?.textAssets?.find(a => a.locationInAd === 'pinName')?.text || ''
    },
    businessName () {
      return this.creativePlan?.accountPlan?.name || ''
    },
    creativeType () {
      return this.creativePlan?.creativeType || ''
    }
  }
}
</script>

<style>
.tilt {
  transform: rotate(45deg);
}
</style>
